// tslint:disable-next-line: no-var-requires
import lodash from 'lodash';

declare global {

  interface String {
    contains(obj: string): boolean;
    replaceAll(str1: string, str2: string, ignore?: boolean): string;
    lowerCaseFirstLetter(): string;
    upperCaseFirstLetter(): string;
    isEmptyOrNullOrUndefined(): boolean;
    isWhiteSpace(): boolean;
    toNumber(): number;
    indexesOf(substring: string): number[];
    getFirstNumberInValue(): number;
    getFirstLetter(): string;
    getLastLetter(): string;
    addCommas(): string;
    isNumber(): boolean;
    /**
     * Replaces all underscores and hyphens to white spaces (does not mutate string)
     */
    normalizeSpaces(): string;
    separateAndTrim(separator: string): string[];
    upperCaseAllFirstLetters(): string;
    // tslint:disable-next-line:variable-name
    ellipsis(number: number): string;
  }

  interface Array<T> {
    propertyValueIsTrue(propertyName: string): boolean;
    diff(a2: any[]): any[];
    insert(ndex: number, item: any): void;
    getHashMap(key: string | number): { [key: string]: T; };
    includesCaseInsensitive(searchTerm: string, key?: string): boolean;
    filterEmptyElements(): T[];
    distinctCaseInsensitive(key?: string): T[];
  }
}

String.prototype.isNumber = function() {
  return /^-{0,1}\d+$/.test(this);
};

String.prototype.ellipsis = function(number) {
  return this.length > number ? this.substring(number) + '...' : this;
};

String.prototype.addCommas = function() {
  if (this === undefined || this === '') { return this; }
  return this.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

String.prototype.contains = function(it: string) { return this.indexOf(it) !== -1; };

String.prototype.getFirstNumberInValue = function() {
  // tslint:disable-next-line:max-line-length
  return this.match(/\d+/)[0].toNumber();
};

String.prototype.replaceAll = function(str1, str2, ignore) {
  // tslint:disable-next-line:max-line-length
  return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, '\\$&'), (ignore ? 'gi' : 'g')), (typeof (str2) === 'string') ? str2.replace(/\$/g, '$$$$') : str2);
};

String.prototype.lowerCaseFirstLetter = function() {
  return this.charAt(0).toLowerCase() + this.slice(1);
};

String.prototype.upperCaseFirstLetter = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.upperCaseAllFirstLetters = function() {
  return this.split(' ').map((word) => word.upperCaseFirstLetter()).join(' ');
}

String.prototype.getFirstLetter = function() {
  return this.charAt(0);
};

String.prototype.getLastLetter = function() {
  return this.charAt(this.length - 1);
};

String.prototype.isWhiteSpace = function() {
  const value = this;
  if (!/\S/.test(value)) {
    return true;
  }
  return false;
};

String.prototype.toNumber = function() {
  return Number(this);
};

String.prototype.normalizeSpaces = function() {
  return this.replaceAll('-', ' ').replaceAll('_', ' ');
};

String.prototype.isEmptyOrNullOrUndefined = function() {
  let result = false;
  const value = this.toString();
  if (value === '' || value === undefined || value === null) {
    result = true;
  }

  return result;
};

String.prototype.indexesOf = function(substring) {
  const result = [];
  for (let i = 0; i < this.length; i++) {
    if (this[i] === substring) {
      result.push(i);
    }
  }
  return result;
};

String.prototype.separateAndTrim = function(separator: string): string[] {
  return this.toString().split(separator).map(v => v.trim());
};

Array.prototype.insert = function(index: number, item: any) {
  this.splice(index, 0, item);
};

Array.prototype.diff = function(compare) {
  return this.filter((elem: any) => !compare.includes(elem));
};

Array.prototype.propertyValueIsTrue = function(propertyName) {
  const array = this;
  let allSelected = true;
  array.forEach((item: any) => {
    if (!item[propertyName]) {
      allSelected = false;
      return;
    }
  });
  return allSelected;
};

/**
 * Gets a map object from array based on key, make sure the value in key is number or string!
 */
Array.prototype.getHashMap = function(key: string | number) {
  if (typeof key === 'string' && key.contains('.')) {
    return this.reduce((map, obj) => (map[`${lodash.get(obj, key)}`] = obj, map), {});
  }
  return this.reduce((map, obj) => (map[`${obj[key]}`] = obj, map), {});
};

Array.prototype.includesCaseInsensitive = function(searchTerm: string, key?: string) {
  const val = searchTerm.trim().toLowerCase();
  const stringValues = !key ? this as string[] : this.map((v) => v[key] as string);
  return lodash.some(stringValues, (value) => value.trim().toLowerCase() === val);
};

Array.prototype.filterEmptyElements = function() {
  return this.filter((element) => !lodash.isEmpty(element));
};

/**
 * Make sure the @param key refers to a string value!
 */
Array.prototype.distinctCaseInsensitive = function(key?: string) {
  return lodash.uniqWith(this, ((a, b) => {
    return key ?
     (a[key] as string).toLowerCase() === (b[key] as string).toLowerCase() :
     (a as string).toLowerCase() === (b as string).toLowerCase();
  }));
};
