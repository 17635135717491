import { LayoutType } from 'app-models/common/layout-type.enum';
import { OpeningPageConfiguration } from 'app-models/report/opening-page-configuration.model';
import { ReportType } from 'app-models/report/report-type.enum';

export const TargetSummaryOpeningPageConfiguration: OpeningPageConfiguration = {
  logo: {
    size: 70,
    layout: LayoutType.Horizontal,
    textSizeFactor: 0.5,
    textStyleClass: 'bold',
    color: '#E91E63'
  },
  backgroundImagePath: 'opening-page-background',
  reportType: ReportType.TargetSummary,
};
