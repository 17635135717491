import { Injectable } from '@angular/core';
import { ChartCard } from 'app-models/chart/chart-card.model';
import { TargetDashboardConfig } from 'app-models/dashboard/dashboard-configuration.model';
import { AggregationItem } from 'app-models/filters/aggregation-item.model';
import { bigNumbersSuffix, deepCloneObject, getEnumKeyByValue } from 'app-scripts/utilities/general';
import { TargetDashboardConfigurations } from './configurations/target-dashboard.configurations';
import { Text } from 'app-models/common/text.model';
import { TranslationService } from 'app-services/translation.service';
import { Card } from 'app-models/card/card.model';
import { TagInformation } from 'app-models/dashboard/tag-information.model';
import { CardConfigurations } from 'app-models/card/card-configurations.model';
import { InfoBox } from 'app-models/info-box/info-box.model';
import { isEmpty } from 'lodash';
import { InfoIcon } from 'app-models/icon/info-icon.model';
import { Priority } from 'app-models/opportunity/priority.enum';
import { KnobService } from 'app-services/knob.service';
@Injectable({ providedIn: 'root' })
export class TargetDashboardReportService {

  constructor(
    private translationService: TranslationService,
    private knobService: KnobService
  ) { }

  getConfigurations = (): TargetDashboardConfig => {
    return TargetDashboardConfigurations;
  }

  buildMonitoredAssetsConfiguartions = (total: number, count: number, showCoverageFirst?: boolean): ChartCard => {
    const config: ChartCard = deepCloneObject(TargetDashboardConfigurations.cards.monitoredAssets);
    config.pieChart.config.showCoverageFirst = showCoverageFirst;
    config.pieChart.config.coverage = {
      count: count || 0,
      total: total || 0
    };

    return config;
  }

  getMonitoredVectorsConfiguartions = (vectorsCount: AggregationItem['doc_count'], rageSize: number): ChartCard => {
    const configuration: ChartCard = deepCloneObject(TargetDashboardConfigurations.cards.vectors);
    configuration.config.description.text = new Text(`${vectorsCount}`.addCommas(), false);

    if (!!configuration.xyChart?.chart?.options?.title) {
      configuration.xyChart.chart.options.title = this.translationService.translate(
        configuration.xyChart.chart.options.title, [`${rageSize}`.addCommas()]
      );
    }

    if (!!configuration.xyChart?.chart?.options?.serieses) {
      configuration.xyChart.chart.options.serieses = configuration.xyChart.chart.options.serieses
        .map(config => {
          config.title = this.translationService.translate(config.title);
          return config;
        });
    }
    return configuration;
  }

  getVulnerableAssetsConfiguartions = (count: number): Card => {
    const config: Card = deepCloneObject(TargetDashboardConfigurations.cards.vulnerableAssets);
    config.config.description.text = new Text(`${count || 0}`.addCommas(), false);
    return config;
  }

  getTagCard = (tagInformation: TagInformation): { card: CardConfigurations; children: InfoBox[]; } => {
    const vectorsCount = isEmpty(tagInformation.vectors) ? 0 : Object.keys(tagInformation.vectors).length;
    const tagBox: { card: CardConfigurations; children: InfoBox[]; } = {
      card: {
        containerClass: 'assets',
        header: {
          title: new Text('dashboard_report.tags.config.header', true, [tagInformation.name, `${vectorsCount}`]),
          infoIcons: [],
        }
      },
      children: [],
    };

    Object.keys(tagInformation.priorities).reverse().forEach((priority: string) => {
      tagBox.card.header.infoIcons.push(this.buildInfoIconWithKnob(priority, tagInformation.priorities[priority]));
    });
    tagInformation.vectors?.forEach((tagVector) =>
      tagBox.children.push(this.getTagsItems(tagVector.priorities, tagVector.name)));
    return tagBox;
  }

  getTagsItems = (
    priorities: any,
    infoText: string,
    highlightOnHover?: boolean,
    options?: { redirect?: { link?: string, queryParams?: unknown } }): InfoBox => {
    const tagBoxInformation: InfoBox = {
      icons: [],
      info: new Text(infoText, false),
      highlightOnHover,
      count: 0,
    };

    tagBoxInformation.redirect = options?.redirect;

    Object.keys(priorities).reverse().forEach((priorityKey: string) => {
      tagBoxInformation.icons.push(this.buildInfoIconWithKnob(priorityKey, priorities[priorityKey]));
      tagBoxInformation.count += priorities[priorityKey];
    });

    return tagBoxInformation;
  }

  private buildInfoIconWithKnob = (priority: string, count: number): InfoIcon => {
    const priorityKey = getEnumKeyByValue(Priority, Number(priority));
    const knobConfiguration = this.knobService.getKnobConfiguration(Number(priority), {
      tooltipPosition: 'top',
      tooltip: new Text(priorityKey, false),
      styleClass: 'knob-text'
    });
    return {
      class: `${priorityKey.toLowerCase()}-priority-background ${priorityKey.toLowerCase()}-priority`,
      text: new Text(bigNumbersSuffix(count), false),
      knob: {
        configuration: knobConfiguration,
        data: Number(priority)
      }
    };
  }


}
