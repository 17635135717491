export enum UserPermissionEnum {
  Ultra_Red = 'ultra_red',
  All = 'All',
  Deep_Enrichment = 'deep_enrichment',
  Discovery = 'discovery',
  MapView = 'map_view',
  Target_Dashboard = 'target_dashboard',
  Target_Asset_Managmenet = 'target_asset_management',
  Target_Vectors = 'target_vectors',
  Target_Discovery = 'target_discovery',
  Target_Reports = 'target_reports',
  Target_Mapping = 'target_mapping',
}
