import { Text } from 'app-models/common/text.model';
import { ListItemConfiguration } from 'app-models/list/list-item-configuration.model';
import { ListItemType } from 'app-models/list/list-item-type.enum';

export const VectorDetailsListConfiguration: ListItemConfiguration[] = [
  {
    title: { text: new Text('identifier', true) },
    path: 'display_id',
    type: ListItemType.Text,
    icon: { class: 'far fa-id-badge' },
    avoidContentTitleCase: true,
    order: 1,
  },
  {
    title: { text: new Text('opportunity_type', true) },
    path: 'opportunity_type',
    type: ListItemType.Text,
    needsTranslation: true,
    icon: { class: 'far fa-chart-pie' },
    order: 2,
  },
  {
    title: { text: new Text('status', true) },
    path: 'status',
    type: ListItemType.Text,
    needsTranslation: true,
    icon: { class: 'fal fa-badge-check' },
    order: 3,
  },
  {
    title: { text: new Text('found_date', true) },
    path: 'found_date',
    type: ListItemType.Date,
    icon: { class: 'far fa-calendar-day' },
    order: 4,
  },
  {
    title: { text: new Text('last_seen_date', true) },
    path: 'last_seen_date',
    type: ListItemType.Date,
    icon: { class: 'far fa-calendar-day' },
    order: 5,
  },
  {
    title: { text: new Text('priority', true) },
    path: 'priority',
    needsTranslation: false,
    type: ListItemType.Text,
    icon: { class: 'fal fa-chart-line' },
    order: 6,
  },
  {
    title: { text: new Text('tags', true) },
    path: 'tags',
    type: ListItemType.Chips,
    icon: { class: 'far fa-tag' },
    order: 7,
  },
  {
    title: { text: new Text('custom_tags', true) },
    path: 'custom_tags',
    type: ListItemType.Chips,
    icon: { class: 'far fa-tag' },
    styleClass: 'custom-tags',
    order: 8,
  }
];
