import { Component, Input } from '@angular/core';
import { FindingsConfiguration } from 'app-models/report/findings-configuration.model';

@Component({
  selector: 'app-findings',
  templateUrl: './findings.component.html',
  styleUrls: ['./findings.component.scss']
})
export class FindingsComponent {
  @Input() configurations: FindingsConfiguration['findings_sections'];

  constructor() { }

}
