import { Component } from '@angular/core';
import { CardConfigurations } from 'app-models/card/card-configurations.model';
import { Card } from 'app-models/card/card.model';
import { ChartCard } from 'app-models/chart/chart-card.model';
import { TargetDashboardConfig } from 'app-models/dashboard/dashboard-configuration.model';
import { Dashboard } from 'app-models/dashboard/dashboard.model';
import { InfoBox } from 'app-models/info-box/info-box.model';
import { XYChart } from '@amcharts/amcharts4/charts';
import { TargetDashboardReportService } from './target-dashboard.service';
import { ChartService } from 'app-components/chart/chart.service';
import { UrlService } from 'app-services/url.service';
import { ReportsDataService } from 'app-services/reports-data.service';
import { TranslationService } from 'app-services/translation.service';
import { ChartTypeEnum } from 'app-models/chart/chart-type.enum';
import { merge } from 'lodash';
import { TagInformation } from 'app-models/dashboard/tag-information.model';
import { getPrioritiesArray } from 'app-scripts/utilities/general';
import { Text } from 'app-models/common/text.model';
import { ReportType } from 'app-models/report/report-type.enum';
import { Report } from 'app-models/report/report.model';
@Component({
  selector: 'app-target-dashboard',
  templateUrl: './target-dashboard.report.html',
  styleUrls: ['./target-dashboard.report.scss']
})
export class TargetDashboardReportComponent {
  readonly ReportType = ReportType;

  dashboard: Dashboard;

  monitoredAssetsCard: ChartCard;
  monitoredVectorsCard: ChartCard;
  vulnerableAssetsCard: Card;
  tagCards: { card: CardConfigurations; children: InfoBox[]; }[];
  selectedTagBox: InfoBox;
  configurations: TargetDashboardConfig;
  xyChart: XYChart;

  constructor(
    protected reportsDataService: ReportsDataService,
    protected urlService: UrlService,
    private targetDashboardService: TargetDashboardReportService,
    private chartService: ChartService,
    private translation: TranslationService) {
  }

  onReportData = (reportData: Report<Dashboard>) => {
    this.dashboard = reportData.data;
    this.initDashboard(this.dashboard);
  }

  private initDashboard = (dashboard: Dashboard) => {
    this.configurations = this.targetDashboardService.getConfigurations();
    this.initMonitoredAssets(dashboard?.total_assets?.count || 0, dashboard?.monitored_assets?.critical || 0);
    this.initMonitoredVectors(dashboard?.vectors_by_date, dashboard?.vectors_count);
    this.initVulnerableAssets(dashboard?.vulnerable_assets?.doc_count as number);
    this.initTagsCards(dashboard?.tags_information);
  }

  private initMonitoredAssets = (total: number, critical: number) => {
    this.monitoredAssetsCard = this.targetDashboardService.buildMonitoredAssetsConfiguartions(total, critical);
  }

  private initMonitoredVectors = (vectorsByDate: Dashboard['vectors_by_date'], vectorsCount: number) => {
    this.monitoredVectorsCard = this.targetDashboardService.getMonitoredVectorsConfiguartions(vectorsCount || 0, vectorsByDate.length);
    this.monitoredVectorsCard.xyChart.chart.id = 'xyChartReport';
    this.monitoredVectorsCard.xyChart.chart.options.legend.fill = 'black';
    this.monitoredVectorsCard.xyChart.chart.options.legend.fontColor = 'black';

    const priorities = getPrioritiesArray();
    this.monitoredVectorsCard.xyChart.hide = !vectorsByDate?.some((item) => {
      return priorities.some(priority => item[priority]);
    });

    if (!this.monitoredVectorsCard.xyChart.hide) {
      setTimeout(() => {
        const chartTooltip = this.translation.translate(this.monitoredVectorsCard.xyChart.chart.tooltip);
        const chartData = vectorsByDate;
        const chartOptions = merge({ tooltip: chartTooltip, title: this.monitoredVectorsCard.xyChart.placeholder },
          this.monitoredVectorsCard.xyChart.chart.options);
        this.xyChart = this.chartService.initChart(
          chartData,
          ChartTypeEnum.Line,
          'xyChartReport',
          chartOptions
        );
      }, 0);
    } else {
      this.monitoredVectorsCard.xyChart.noDataPlaceholder = new Text('dashboard_view.cards.vectors.no_data_placeholder', true, [`${vectorsByDate?.length}`]);
    }
  }

  private initVulnerableAssets = (count: number) => {
    this.vulnerableAssetsCard = this.targetDashboardService.getVulnerableAssetsConfiguartions(count);
  }

  private initTagsCards = (tagsInformation: TagInformation[]) => {
    this.tagCards = [];
    tagsInformation?.forEach((taginformation: any) => {
      const tag: any = this.targetDashboardService.getTagCard(taginformation);
      this.tagCards.push(tag);
    });
  }

}
