import { Injectable } from '@angular/core';
import { TargetSummaryReportData } from 'app-models/target-summary/target-summary-report-data.model';
import { VectorMetricsCardsConfigurations } from './configurations/vector-metrics.configuration';
import { deepCloneObject, getEnumKeyByValue } from 'app-scripts/utilities/general';
import { Priority } from 'app-models/opportunity/priority.enum';
import { GenericObject } from 'app-models/common/generic-object';
import { PieChart } from 'app-models/chart/pie-chart.model';
import { Text } from 'app-models/common/text.model';
import moment from 'moment';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class VectorMetricsService {

  constructor() { }

  getConfigurations = (reportData: TargetSummaryReportData) => {
    const config = deepCloneObject(VectorMetricsCardsConfigurations);
    if (reportData.totalVectors) {
      config['totalVectors'].config.description = { text: new Text(reportData['totalVectors'].toString(), false) };
    }
    if (reportData.vectorsByPriority) {
      config.vectorsByPriority.pieChart = this.buildVectorsByPriotityConfig(reportData.vectorsByPriority, config.vectorsByPriority.pieChart);
    }
    return config;
  }

  buildHighestPriorityVectorsTableData = (highestPriorityVectors: any[]) => {
    if (isEmpty(highestPriorityVectors)) { return; }
    const vectorPrioritiesData = highestPriorityVectors.map((vector) => {
      const lifetime = this.getLifetime(vector.last_seen, vector.opportunity.date);
      return {
        priority: vector.opportunity.priority,
        lifetime,
        display_id: vector.display_id,
        tag: vector.opportunity.tags[0],
        name: vector.opportunity.entity,
      }
    });
    return vectorPrioritiesData;
  }

  private buildVectorsByPriotityConfig = (vectorsByPriority: GenericObject<number, Priority>, chartConfig: PieChart) => {
    const chartData: { key: string, color: string, value?: number }[] = deepCloneObject(chartConfig.chart.data);
    const filteredData = [];
    chartData.forEach((dataItem) => {
      if (vectorsByPriority[dataItem.key]) {
        filteredData.push({
          value: vectorsByPriority[dataItem.key],
          key: `${vectorsByPriority[dataItem.key]} ${getEnumKeyByValue(Priority, Number(dataItem.key))}`,
          color: dataItem.color
        });
      }
    });
    chartConfig.chart.data = filteredData;
    return chartConfig;
  }

  private getLifetime = (last_seen: number, found_date: number) => {
    const lastSeen = moment(last_seen!).utc();
    const foundDate = moment(found_date).utc();
    const mttr = lastSeen.diff(foundDate, 'minutes', true);
    const MTTRDuration = moment.duration(mttr, 'minutes');
    const days = Math.floor(MTTRDuration.asDays());
    const hours = MTTRDuration.hours();
    const minutes = MTTRDuration.minutes();
    const formattedTime = `${days}d ${hours}h ${minutes}m`;
    return formattedTime;
  }
}
