import { Injectable } from '@angular/core';
import { LocalizationConfiguration } from 'app-configurations/localization.configuration';
import { UserService } from './user.service';
import { ApplicationDataStorageService } from './applicationDataStorage.service';
import * as _ from 'lodash';

@Injectable()
export class LocalizationService {
  constructor(private userService: UserService, private applicationGlobalsService: ApplicationDataStorageService) { }

  // base language is english
  getBaseLocalizationFile = (): any => {
    // tslint:disable-next-line:no-string-literal
    return LocalizationConfiguration['en'];
  }

  getLocalizationFile = (): any => {
    const lang = this.userService.getLanguage();
    return LocalizationConfiguration[lang];
  }

  getLanguageValue = (key: string): any => {
    const lang = this.applicationGlobalsService.localization;
    return _.get(lang, key);
  }

  getBaseLanguageValue = (key: string): any => {
    const lang = this.applicationGlobalsService.baseLocalization;
    return lang[key];
  }
}
