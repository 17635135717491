import { Component } from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'main-layout',
    templateUrl: 'main.layout.html',
    styleUrls: ['./main.layout.scss']
})

export class MainLayoutComponent {

}

