import { AssetScore } from 'app-models/common/asset-score.enum';
import { Text } from 'app-models/common/text.model';
import { KnobConfiguration } from 'app-models/knob/knob.model';

export const ScoreKnobConfiguration: KnobConfiguration = {
  readonly: true,
  min: AssetScore.None,
  max: AssetScore.Critical,
  size: 35,
  step: 1,
  tooltipPosition: 'right',
};

export const ScoreKnobTooltips = {
  [AssetScore.None]: new Text('score_level.none'),
  [AssetScore.Little]: new Text('score_level.little_to_no_risk'),
  [AssetScore.Low]: new Text('score_level.low_risk'),
  [AssetScore.Medium]: new Text('score_level.medium_risk'),
  [AssetScore.High]: new Text('score_level.high_risk'),
  [AssetScore.Critical]: new Text('score_level.critical'),
};

export const ScoreKnobColors = {
  [AssetScore.None]: '#0580E3',
  [AssetScore.Little]: '#A4A4A4',
  [AssetScore.Low]: '#CA9D8F',
  [AssetScore.Medium]: '#E5A366',
  [AssetScore.High]: '#E07B5B',
  [AssetScore.Critical]: '#E91E63',
};
