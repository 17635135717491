import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { IndexHelperFactory } from 'app-factories/charts.factory';
import { ChartOptions } from 'app-models/chart/chart-option.model';
import { ChartHelper } from './chart.helper';
import { isUndefined } from 'lodash';

export class XYChartHelper implements IndexHelperFactory {

  private chartHelper = new ChartHelper();

  initChart = (data: any, id: string, options?: ChartOptions) => {
    const chart = this.renderChart(id, data);
    chart.zoomOutButton.disabled = true;
    if (!!options?.title) {
      const title = chart.titles.create();
      title.text = `[font-size: ${options?.titleFont?.size || 12}, font-weight: ${options?.titleFont?.weight || 'bold'}]${options?.title}[/]`;
      title.textAlign = 'middle';
      title.fill = am4core.color(options?.titleColor || '#000000');
      if (!isUndefined(!!options.titleMarginBottom)) {
        title.marginBottom = options.titleMarginBottom;
      }
    }

    chart.legend = this.getChartLegend(options);
    chart.xAxes.push(this.getCategoryAxis(options, data));
    chart.yAxes.push(this.getValueAxis(options));

    if (!!options?.serieses) {
      options?.serieses.forEach((prioritySeries) => {
        const seriesOptions = { ...options, series: prioritySeries };
        chart.series.push(this.chartHelper.getSeries(seriesOptions, chart) as am4charts.XYSeries);
      });
    } else {
      chart.series.push(this.chartHelper.getSeries(options, chart) as am4charts.XYSeries);
    }

    if (!isUndefined(options?.maskBullets)) {
      chart.maskBullets = options.maskBullets;
    }

    const cursor = new am4charts.XYCursor();
    cursor.lineX.disabled = options?.cursor?.lineY?.disabled;
    cursor.lineY.disabled = options?.cursor?.lineX?.disabled;

    chart.cursor = cursor;
    return chart;
  }

  private getChartLegend = (options: ChartOptions): am4charts.Legend => {
    const legend = new am4charts.Legend();
    if (!!options?.legend?.labelDefaultColor) {
      legend.labels.template.fill = am4core.color(options?.legend?.labelDefaultColor);
    }

    if (!!options?.legend?.disabled) {
      legend.markers.template.disabled = options?.legend?.disabled;
      legend.__disabled = true;
    }

    if (!!options?.legend?.useDefaultMarker) {
      legend.useDefaultMarker = options.legend.useDefaultMarker;
    }

    if (!!options?.legend?.fontColor) {
      legend.labels.template.fill =  am4core.color(options.legend.fontColor || 'white');
    }

    if (!!options?.legend?.fontSize) {
      legend.fontSize = options.legend.fontSize;
    }

    if (!!options?.legend?.maxWidth) {
      legend.maxWidth = options.legend.maxWidth;
    }

    if (!!options?.markers?.height) {
      legend.markers.template.height = options.markers.height;
    }

    if (!!options?.markers?.width) {
      legend.markers.template.width = options.markers.width;
    }

    return legend;
  }

  private getValueAxis = (options: ChartOptions): am4charts.ValueAxis => {
    const valueAxis = new am4charts.ValueAxis();
    valueAxis.tooltip.disabled = options?.valueAxis?.tooltip?.disabled;
    valueAxis.hidden = options?.valueAxis?.hidden;
    valueAxis.maxHeight = options?.valueAxis?.maxHeight;
    valueAxis.minHeight = options?.valueAxis?.minHeight;
    valueAxis.logarithmic = options?.valueAxis?.logarithmic;
    valueAxis.min = options?.valueAxis?.minValue || 0;
    valueAxis.maxPrecision = options?.valueAxis?.allowFloat ? 2 : 0;
    valueAxis.events.on('sizechanged', () => {
      valueAxis.renderer.grid.template.stroke = am4core.color(options?.valueAxis?.fontColor || '#FFFFFF');
      valueAxis.renderer.labels.template.fill = am4core.color(options?.valueAxis?.fontColor || '#FFFFFF');
    });
    return valueAxis;
  }

  private getCategoryAxis = (options: ChartOptions, data?: any): am4charts.CategoryAxis => {
    const categoryAxis = new am4charts.CategoryAxis();
    categoryAxis.dataFields.category = options?.categoryAxis?.category;
    categoryAxis.hidden = options?.categoryAxis?.hidden;
    categoryAxis.tooltip.disabled = options?.categoryAxis?.tooltip?.disabled;
    categoryAxis.events.on('sizechanged', (event) => {
      categoryAxis.renderer.grid.template.disabled = options?.categoryAxis?.grid?.disabled;
      categoryAxis.renderer.grid.template.location = options?.categoryAxis?.grid?.location;
      const label = categoryAxis.renderer.labels.template;
      label.truncate = options?.categoryAxis?.label?.truncate;
      label.maxWidth = options?.categoryAxis?.label?.maxWidth - (data?.length * 5);
      label.fontSize = options?.categoryAxis?.label?.fontSize;
      label.wrap = options?.categoryAxis?.label?.wrap;
      if (!!options?.categoryAxis?.label?.fill) {
        label.fill = am4core.color(options?.categoryAxis?.label?.fill);
      }

      if (!options?.categoryAxis?.label?.autoRotate) {
        label.rotation = options?.categoryAxis?.label?.rotation;
        const axis = event.target;
        axis.renderer.labels.template.horizontalCenter = 'middle';
        axis.renderer.labels.template.verticalCenter = 'middle';
      } else {
        // auto rotate labels according to cell size
        const axis = event.target;
        const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
        if (cellWidth < axis.renderer.labels.template.maxWidth) {
          axis.renderer.labels.template.rotation = options?.categoryAxis?.label?.rotation || -45;
          axis.renderer.labels.template.horizontalCenter = 'right';
          axis.renderer.labels.template.verticalCenter = 'middle';
        }
        else {
          axis.renderer.labels.template.rotation = 0;
          axis.renderer.labels.template.horizontalCenter = 'middle';
          axis.renderer.labels.template.verticalCenter = 'top';
        }
      }
      categoryAxis.renderer.minGridDistance = options?.categoryAxis?.grid?.minGridDistance;
      if (!isUndefined(options?.categoryAxis?.cell?.cellStartLocation)) {
        categoryAxis.renderer.cellStartLocation = options?.categoryAxis?.cell?.cellStartLocation;
      }
      if (!isUndefined(options?.categoryAxis?.cell?.cellEndLocation)) {
        categoryAxis.renderer.cellEndLocation = options?.categoryAxis?.cell?.cellEndLocation;
      }
    });

    return categoryAxis;
  }

  private renderChart = (id: string, data: any) => {
    const chart = am4core.create(id, am4charts.XYChart);
    chart.logo.__disabled = true;
    chart.data = data;
    chart.hiddenState.properties.opacity = 0;
    return chart;
  }
}
