import { Component, Input, OnInit } from '@angular/core';
import { OpeningPageConfiguration } from 'app-models/report/opening-page-configuration.model';
import { ImageService } from 'app-services/image.service';
import { Image } from 'app-configurations/images.configuration';

@Component({
  selector: 'app-opening-page',
  templateUrl: './opening-page.component.html',
  styleUrls: ['./opening-page.component.scss']
})
export class OpeningPageV2Component implements OnInit {

  @Input() configuration: OpeningPageConfiguration;
  backgroundImage: string;

  constructor(
    private imageService: ImageService
  ) { }

  ngOnInit(): void {
    const imagePath = this.imageService.getImage(Image[this.configuration.backgroundImagePath || 'opening-page-background']);
    this.backgroundImage = `url(${imagePath})`;
  }
}
