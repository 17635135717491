import { Injectable } from '@angular/core';
import { ApplicationDataStorageService } from 'app-services/applicationDataStorage.service';
import { UserService } from 'app-services/user.service';
import { UrlService } from 'app-services/url.service';
import { UserLanguage } from 'app-models/user/user.module';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import { getHttpRequestOptions } from 'app-scripts/utilities/general';
import { ApplicationConfigurationsService } from './applicationConfigurations.service';
import { AuthenticationDetails } from 'app-models/user/authentication-details.model';

@Injectable()
export class ApplicationLoadService {

  // tslint:disable-next-line:variable-name
  app_name: string;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    private applicationDataStorageService: ApplicationDataStorageService,
    private userService: UserService,
    private urlService: UrlService,
    private httpClient: HttpClient,
    private applicationConfigurationsService: ApplicationConfigurationsService,
  ) { }

  initApplication(): Promise<any> {
    this.applicationDataStorageService.setGlobalConfigurations();
    const controllerName = 'users';
    const methodName = 'me';
    const date = moment().unix();
    const url = this.urlService.getRequestUrl(controllerName, methodName) + `?timeStamp=${date}`;
    return this.httpClient.get(url, getHttpRequestOptions())
      .toPromise()
      .then(async (userResult: AuthenticationDetails) => {
        await this.userService.set({
          language: userResult?.payload?.userObj.language ? userResult?.payload?.userObj.language : UserLanguage.English,
          ...userResult?.payload?.userObj
        });
        this.applicationConfigurationsService.initConfiguration();
      },
        (e) => {
          const currentUrl = window.location.href;
          const authUrl = this.applicationDataStorageService.globalConfigurations.authUrl;
          window.location.href = authUrl + encodeURIComponent(currentUrl);
        });
  }

}
