import { Component, Input } from '@angular/core';
import { Chart } from 'app-models/chart/chart-configurations.model';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent {

  @Input() chart: Chart;
  constructor() {}
}

