import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InfoBox } from 'app-models/info-box/info-box.model';
import { ApplicationDynamicRoutes } from 'app-services/applicationDynamicRoutes.service';


@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent {
  @Input() data: InfoBox;
  constructor(
  ) { }

}
