export enum TableCellType {
  Text = 'text',
  Date = 'date',
  Image = 'image',
  Icon = 'icon',
  Chips = 'chips',
  Badge = 'badge',
  Checkbox = 'checkbox',
  Knob = 'knob',
  Counter = 'counter',
  Array = 'array',
  MultiCell = 'multi_cell',
  TextWithSubtitle = 'text_with_subtitle'
}
