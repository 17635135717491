import { Injectable } from '@angular/core';
import { UrlService } from 'app-services/url.service';
import { isEmpty } from 'lodash';
import { Router } from '@angular/router';
import { ApplicationDynamicRoutes } from './applicationDynamicRoutes.service';
import { ApplicationDataStorageService } from './applicationDataStorage.service';
import { LocalizationService } from './localization.service';
import { UserLanguage } from 'app-models/user/language.enum';
import { UserService } from './user.service';

@Injectable()
export class ApplicationConfigurationsService {

  constructor(
      private localizationService: LocalizationService,
      private userService: UserService,
      private urlService: UrlService,
      private router: Router,
      private applicationDynamicRoutes: ApplicationDynamicRoutes,
      private applicationDataStorageService: ApplicationDataStorageService,
    ) { }

  initConfiguration = (): void => {
    this.applicationDataStorageService.appName = 'reportmanager';
    this.setLocalizationLanguage();
    this.setApplicationRoutes();
  }

  private setApplicationRoutes = (): void => {
    this.applicationDynamicRoutes.set();
    const currentPath = this.urlService.getCurrentPath();
    const route = isEmpty(currentPath) ?
        this.applicationDynamicRoutes.getDefaultRoute() :
        currentPath;
    this.router.navigateByUrl(route);
  }

  private setLocalizationLanguage = (): void => {
    const localizationFile = this.localizationService.getLocalizationFile();
    if (this.userService.getLanguage() !== UserLanguage.English) {
      const baseLocalizationFile = this.localizationService.getBaseLocalizationFile();
      this.applicationDataStorageService.baseLocalization = baseLocalizationFile;
    }
    this.applicationDataStorageService.localization = localizationFile;
  }

}
