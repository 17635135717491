import { Injectable } from '@angular/core';
import { TargetSummaryOpeningPageConfiguration } from './configurations/target-summary-opening-page.configuration';
import { TargetSummaryReportData } from 'app-models/target-summary/target-summary-report-data.model';
import { Text } from 'app-models/common/text.model';

@Injectable({
  providedIn: 'root'
})
export class TargetSummaryReportService {

  constructor() { }

  getConfigurations = (reportData: TargetSummaryReportData) => {
    const openingPage = TargetSummaryOpeningPageConfiguration;
    return { openingPage };
  }

}
