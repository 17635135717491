import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { Text } from 'app-models/common/text.model';
import { ProgressBarMode } from 'app-models/progress-bar/progress-bar-mode.enum';
import { ProgressBarConfiguration } from 'app-models/progress-bar/progress-bar-configuration.model';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges, AfterViewInit {
  @Input() config: ProgressBarConfiguration;
  @Output() notify: EventEmitter<any> = new EventEmitter();
  processBarCurrentText: Text;
  processBarCurrentValue: number;
  progressBarTextsArray: Text[];
  processBarAdvanceValue: number;
  mode: ProgressBarMode;
  @ViewChild('progressbar', {static: false}) progressbarRef: ElementRef<any>;
  constructor() { }

  ngOnChanges(): void {
    this.initializeConfiguration();
    this.setCustomLabel();
  }

  ngAfterViewInit(): void {
    this.setCustomLabel();
  }

  private initializeConfiguration = () => {
    this.processBarCurrentText = this.config.text;
    this.processBarCurrentValue = this.config.processBarCurrentValue;
    this.progressBarTextsArray = this.config.progressBarTextsArray;
    this.processBarAdvanceValue = this.config.processBarAdvanceValue;
    this.mode = this.config.mode || ProgressBarMode.Indeterminate;
  }

  private setCustomLabel = () => {
    if (this.config.customLabel && this.progressbarRef) {
      const labelElement = this.progressbarRef.nativeElement.querySelector('.p-progressbar-label');
      labelElement.innerText = this.config.customLabel;
    }
  }

}
