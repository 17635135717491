import { Injectable } from '@angular/core';
import { Text } from 'app-models/common/text.model';
import { KnobOptions } from 'app-models/knob/knob-options.model';
import { KnobConfiguration } from 'app-models/knob/knob.model';
import { deepCloneObject, getLowestAssetScore } from 'app-scripts/utilities/general';
import { ScoreKnobConfiguration, ScoreKnobColors, ScoreKnobTooltips } from 'configurations/asset-score.configuration';
import { isEmpty, isUndefined } from 'lodash';

@Injectable({providedIn: 'root'})
export class KnobService {

  private RANGE_COLOR_OPACITY = '60';
  constructor() { }

  getKnobConfiguration = (score: number, options?: KnobOptions): KnobConfiguration => {
    const config = this.getKnobConfigurationByValue(score, options?.decimalAccuracy);
    config.size = options?.size || config.size;
    config.tooltipPosition = options?.tooltipPosition || config.tooltipPosition;
    config.styleClass = options?.styleClass;
    config.class = options?.class;
    config.title = options?.title;

    if (!isUndefined(options?.tooltip) && !isEmpty(options?.tooltip)) {
      config.tooltip = options?.tooltip;
    }

    if (options?.disableTooltip) {
      delete config.tooltip;
    }

    return config;
  }

  getKnobBackgroundColor = (color: string) => {
    return color + this.RANGE_COLOR_OPACITY;
  }

  getKnobConfigurationByValue = (score: number, decimalAccuracy?: number): KnobConfiguration => {
    score = score || getLowestAssetScore();
    const config = deepCloneObject(ScoreKnobConfiguration);
    return {
      ...config,
      decimalAccuracy,
      color: this.getScoreColorByValue(score),
      tooltip: this.getScoreTooltipByValue(score)
    };
  }

  getScoreColorByValue = (value: number): string => {
    const roundedScore = Math.round(value);
    return ScoreKnobColors[roundedScore];
  }

  getScoreTooltipByValue = (value: number): Text => {
    const roundedScore = Math.round(value);
    return ScoreKnobTooltips[roundedScore];
  }

}
