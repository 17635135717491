import { Pipe, PipeTransform } from '@angular/core';
import { bigNumbersSuffix } from 'app-scripts/utilities/general';

@Pipe({ name: 'bigNumbersSuffix' })

export class AddBigNumbersSuffix implements PipeTransform {
  constructor() { }

  transform(value: number): string {
    if (value === undefined) { return; }
    return bigNumbersSuffix(value);
  }
}
