import { Component, Input } from '@angular/core';
import { AppendixConfiguration } from '../../models/appendix/appendix-confguration.model';
import { TableAppendixData } from '../../models/appendix/appendixes-data.model';

@Component({
  selector: 'app-table-appendix',
  templateUrl: './table-appendix.component.html',
  styleUrls: ['./table-appendix.component.scss']
})
export class TableAppendixComponent {

  @Input() configuration: AppendixConfiguration;
  @Input() data: TableAppendixData;

  constructor() { }

}
