import { Text } from 'app-models/common/text.model';
import { ReportSectionBodyItemType } from 'app-models/report/report-section/report-section-body-item-type.enum';
import { ReportSectionConfiguration } from 'app-models/report/report-section/report-section-configuration.model';
import { FindingsReportSectionBodyItemIdentifier } from 'app-models/report/report-section/findings-report-section-body-item-identifier.enum';

export const VectorFindingsSectionConfiguration: ReportSectionConfiguration = {
  header: {
    icon: {
      class: 'far fa-bug color-primary-pink f-s-22 f-w-500',
      backgroundColor: '#eb1c6229'
    },
    subtitle: {
      text: new Text('asset', true),
      styleClass: 'color-default-grey f-s-20'
    },
    title: {
      styleClass: 'color-primary-pink f-s-26 bold'
    },
    indicators: [
      {
        icon: { class: 'fas fa-flag f-s-26 color-default-grey' },
        showIf: (vector) => vector.flagged
      }
    ]
  },
  body: [
    {
      type: ReportSectionBodyItemType.List,
      identifier: FindingsReportSectionBodyItemIdentifier.Details,
      title: {
        text: new Text('vector_details', true),
        styleClass: 'color-primary-pink f-2-16 bold'
      },
      order: 1,
    },
    {
      type: ReportSectionBodyItemType.Timeline,
      identifier: FindingsReportSectionBodyItemIdentifier.Steps,
      title: {
        text: new Text('vector_steps', true),
        styleClass: 'color-primary-pink f-2-16 bold'
      },
      order: 2
    },
    {
      type: ReportSectionBodyItemType.Timeline,
      identifier: FindingsReportSectionBodyItemIdentifier.Timeline,
      title: {
        text: new Text('vector_timeline', true),
        styleClass: 'color-primary-pink f-2-16 bold'
      },
      order: 3
    }
  ]
};
