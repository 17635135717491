import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'app_titlecase' })

export class AppTitleCasePipe implements PipeTransform {
  constructor() { }

  transform(value: string, avoidTitleCase?: boolean): string {
    if (avoidTitleCase) { return value; }
    return value.upperCaseAllFirstLetters();
  }
}
