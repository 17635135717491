import { Component, Input, OnInit } from '@angular/core';
import { GenericObject } from 'app-models/common/generic-object';
import { SummaryCard } from 'app-models/summary-card/summary-card.model';
import { SummaryCardsService } from './summary-cards.service';
import { SummaryCardData } from '../../../models/summary-card/summary-card-data.model';

@Component({
  selector: 'app-summary-cards',
  templateUrl: './summary-cards.component.html',
  styleUrls: ['./summary-cards.component.scss']
})
export class SummaryCardsComponent implements OnInit {

  @Input() configuration: GenericObject<SummaryCard>;
  @Input() data: GenericObject<SummaryCardData>;

  summaryCards: SummaryCard[];

  constructor(
    private summaryCardsService: SummaryCardsService
  ) { }

  ngOnInit(): void {
    this.summaryCards = this.summaryCardsService.getConfigurations(this.configuration, this.data);
  }

}
