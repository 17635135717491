import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorsLayoutComponent } from 'app-layouts/errors/errors-layout/errors-layout';
import { UnauthorizedPageComponent } from 'app-pages/unauthorized/unauthorized.page';

const routes: Routes = [
  {
    path: 'error', component: ErrorsLayoutComponent,
    children: [
      {
        path: 'unauthorized', component: UnauthorizedPageComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
