import { ChartTypeEnum } from 'app-models/chart/chart-type.enum';
import { PieChartHelper } from 'app-helpers/charts/pie-chart.helper';
import { XYChartHelper } from 'app-helpers/charts/xy-chart.helper';
import { ChartOptions } from 'app-models/chart/chart-option.model';
import { ForceDirectedTreeHelperV2 } from 'app-helpers/charts/force-directed-tree-v2.helper';

export interface IndexHelperFactory {
  initChart(data: any, id: string, options?: ChartOptions): any;
}

// @ts-ignore
export const ChartsHelperByTypeMap = new Map([
  [ChartTypeEnum.ForceDirectedTreeV2, new ForceDirectedTreeHelperV2()],
  [ChartTypeEnum.Pie, new PieChartHelper()],
  [ChartTypeEnum.Line, new XYChartHelper()],
  [ChartTypeEnum.Bar, new XYChartHelper()]
]);
