import { ChartTypeEnum } from 'app-models/chart/chart-type.enum';
import { SeriesType } from 'app-models/chart/series-type.enum';
import { Text } from 'app-models/common/text.model';
import { TableCellType } from 'app-models/table/table-cell-type.enum';
import { TargetSummaryConfig } from 'app-models/target-summary/target-summary-configuration.model';

export const VectorMetricsCardsConfigurations: TargetSummaryConfig['vectorMetrics'] = {
  header: { title: { styleClass: 'color-primary-pink f-s-20 bold', text: new Text('vector_metrics', true) } },
  totalVectors: {
    name: 'totalVectors',
    emptyStatePlaceholder: new Text('target_summary_report.total_vectors.empty_state', true),
    config: {
      header: {
        title: new Text('target_summary_report.total_vectors.title', true),
        leftIcon: {
          class: 'far fa-bug f-s-18 color-primary-pink p-r-8 f-w-500',
        }
      },
    },
  },
  vectorsByPriority: {
    card: {
      name: 'vectorsByPriority',
      emptyStatePlaceholder: new Text('target_summary_report.vectors_by_priority.empty_state', true),
      config: {
        header: {
          title: new Text('target_summary_report.vectors_by_priority.title', true),
          leftIcon: {
            class: 'far fa-tachometer f-s-18 color-primary-pink p-r-8 f-w-500',
          }
        },
      },
    },
    pieChart: {
      chart: {
        id: 'vectorsByPriorityPieChart',
        type: ChartTypeEnum.Pie,
        options: {
          seriesType: SeriesType.Pie,
          dataFields: {
            value: 'value',
            category: 'key'
          },
          disableChartTooltip: true,
          series: {
            label: {
              disabled: true,
            },
          },
          legend: {
            fontSize: 12,
            valueLabels: { disabled: true },
            labelsTemplateText: "[bold {color}]{name}[/]",
            contentAlign: 'right',
          },
          markers: {
            width: 12,
            height: 12
          }
        },
        data: [
          {
            key: 5,
            color: '#E91E63',
          },
          {
            key: 4,
            color: '#E5A366',
          },
          {
            key: 3,
            color: '#CA9D8F',
          },
          {
            key: 2,
            color: '#A4A4A4',
          },
          {
            key: 1,
            color: '#A4A4A4',
          },
        ]
      }
    }
  },
  highestPriorityVectors: {
    card: {
      name: 'highestPriorityVectors',
      emptyStatePlaceholder: new Text('target_summary_report.highest_priority_vectors.empty_state', true),
      config: {
        header: {
          title: new Text('target_summary_report.highest_priority_vectors.title', true),
          leftIcon: {
            class: 'far fa-bug f-s-18 color-primary-pink p-r-8 f-w-500',
          },
          rightText: {
            text: new Text('lifetime', true),
            class: 'color-default-grey p-r-30'
          }
        },
      },
    },
    table: {
      tableConfiguration: {
        styleClass: 'vectors-priorities-table'
      },
      columns: [
        {
          field: 'priority',
          types: [TableCellType.Knob],
          width: '15%',
          alignTop: true,
          styleClass: 'knob-text',
          knobOptions: {
            size: 46
          }
        },
        {
          field: 'name',
          types: [TableCellType.TextWithSubtitle],
          width: '65%',
          subFields: ['display_id', 'tag']
        },
        {
          field: 'lifetime',
          types: [TableCellType.Text],
          width: '20%',
          styleClass: 'color-default-grey pull-rught'
        },
      ]
    }
  }
};
