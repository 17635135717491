import { Component, Input } from '@angular/core';
import { EmptyStateConfiguration } from 'app-models/empty-state/empty-state-configuration.model';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

  @Input() configuration: EmptyStateConfiguration;

  constructor() { }

}
