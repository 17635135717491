import { deepCloneObject, getPercentage } from 'app-scripts/utilities/general';
import { TranslationService } from 'app-services/translation.service';
import { Vector } from 'app-models/vectors/vector.model';
import { ListItemConfiguration } from 'app-models/list/list-item-configuration.model';
import { VectorDetailsListConfiguration } from 'app-reports/vector-findings/configurations/vector-details-list.configuration';
import { GenericObject } from 'app-models/common/generic-object';
import { RemediationGroup } from 'app-models/remediations/remediation-group.models';
import { isEmpty, isUndefined, sortBy } from 'lodash';
import { Text } from 'app-models/common/text.model';
import { ListItemType } from 'app-models/list/list-item-type.enum';
import { LayoutType } from 'app-models/common/layout-type.enum';
import { Remediation } from 'app-models/remediations/remediation.model';
import { Icon } from 'app-models/common/icon.model';
import { ProgressBarConfiguration } from 'app-models/progress-bar/progress-bar-configuration.model';
import { Injectable } from '@angular/core';
import { TimelineStep } from 'app-models/timeline/timeline-step.model';
import { TimelineStepType } from 'app-models/timeline/timeline-step-type.enum';

@Injectable({
  providedIn: 'root'
})
export class FindingsReportHelper {

  constructor(
    private translationService: TranslationService
  ) { }

  buildVectorDetailsList = (vector: Vector): ListItemConfiguration[] => {
    const list = deepCloneObject(VectorDetailsListConfiguration);
    list?.forEach((item) => {
      if (item.path === 'priority') {
        item.content = this.translationService.translate(`priorities.${vector[item.path]}`);
      } else {
        item.content = vector[item.path];
      }
    });
    return list;
  }

  buildAssetRemediationGroups = (remediation_groups?: GenericObject<RemediationGroup>): ListItemConfiguration[] | undefined => {
    const list: ListItemConfiguration[] = [];
    Object.keys(remediation_groups || {})?.forEach((group) => {
      const remediationSteps = sortBy(remediation_groups[group].remediationsItems, 'index');
      list.push({
        title: {
          text: new Text(group, false),
          styleClass: 'bold',
          avoidTitleCase: true,
        },
        type: ListItemType.List,
        content: this.buildRemediationGroupSteps(remediationSteps),
        layout: LayoutType.Vertical
      });
    });
    if (isEmpty(list)) {
      return undefined;
    }
    return list;
  }

  buildStepsProgressBar = (remediation_groups: GenericObject<RemediationGroup>, progressBarConfiguration: ProgressBarConfiguration) => {
    const { stepsDone, totalSteps } = this.getRemediationsStepsProgressCounters(remediation_groups);
    if (isUndefined(progressBarConfiguration)) { return; }
    progressBarConfiguration.processBarCurrentValue = getPercentage(stepsDone, totalSteps);
    progressBarConfiguration.title.parameters = [`${stepsDone} / ${totalSteps}`];
  }

  buildVectorSteps = (vector: Vector): TimelineStep[] | undefined => {
    const steps: TimelineStep[] = [];
    this.setIPResolvingStep(vector, steps);
    this.setExploitDemoStep(vector, steps);
    this.setAnalysisStep(vector, steps);
    if (isEmpty(steps)) {
      return undefined;
    }
    return steps;
  }

  private setIPResolvingStep = (vector: Vector, steps: TimelineStep[]): TimelineStep[] => {
    if (!!vector.opportunity_details?.ip) {
      steps.push({
        title: new Text('resolved_ip', true),
        type: TimelineStepType.IP,
        content: { ip: vector.opportunity_details?.ip, port: vector.opportunity_details?.port }
      });
    }
    return steps;
  }

  private setExploitDemoStep = (vector: Vector, steps: TimelineStep[]) => {
    if (!isEmpty(vector.exploit_demos)) {
      steps.push({
        title: new Text('proof_of_concept', true),
        type: TimelineStepType.Multiple,
        content: this.buildNestedStepsOfExploitDemo(vector.exploit_demos)
      });
    }
  }

  private setAnalysisStep = (vector: Vector, steps: TimelineStep[]) => {
    if (!isEmpty(vector.description)) {
      steps.push({
        title: new Text('vector_analysis', true),
        type: TimelineStepType.Text,
        content: new Text(vector.description, false)
      });
    }
  }

  private buildNestedStepsOfExploitDemo = (exploit_demos: Vector['exploit_demos']): TimelineStep[] => {
    const nestedSteps: TimelineStep[] = [];
    exploit_demos?.forEach((exploit_demo) => {
      if (!isEmpty(exploit_demo?.request_method) && !isEmpty(exploit_demo?.status_code)) {
        nestedSteps.push({
          title: new Text(''),
          type: TimelineStepType.Response,
          content: { method: exploit_demo?.request_method?.toUpperCase(), status_code: exploit_demo?.status_code }
        });
      }
      if (!isEmpty(exploit_demo?.request_path)) {
        nestedSteps.push({
          title: new Text('exploit_demo_url', true),
          type: TimelineStepType.Text,
          content: new Text(exploit_demo?.request_path, false)
        });
      }
      if (!isEmpty(exploit_demo?.raw_request)) {
        nestedSteps.push({
          title: new Text('raw_request', true),
          type: TimelineStepType.Code,
          content: {
            code: exploit_demo?.raw_request,
            languages: ['http']
          }
        });
        if (!isEmpty(exploit_demo?.response)) {
          nestedSteps.push({
            title: new Text('raw_response', true),
            type: TimelineStepType.Code,
            content: {
              code: exploit_demo?.response,
              languages: ['http']
              }
          });
        }
      } else {
        if (!isEmpty(exploit_demo?.headers)) {
          const stringifiedHeaders = exploit_demo?.headers?.map(
            (header: { key: string; value: string }) => `${header.key}: ${header.value}`
          )?.join('\n') || '';
          nestedSteps.push({
            title: new Text('exploit_demo_headers', true),
            type: TimelineStepType.Text,
            content: new Text(stringifiedHeaders, false)
          });
        }
        if (!isEmpty(exploit_demo?.body)) {
          nestedSteps.push({
            title: new Text('exploit_demo_body', true),
            type: TimelineStepType.Text,
            content: new Text(exploit_demo?.body, false)
          });
        }
      }});
    return nestedSteps;
  }

  private buildRemediationGroupSteps = (remediation_steps: Remediation[]): { icon?: Icon, text?: string, styleClass?: string }[] => {
    const steps: { icon?: Icon, text?: string, styleClass?: string }[] = [];
    remediation_steps?.forEach((step) => {
      steps.push({
        icon: { class: 'fal ' + (step.done ? 'fa-check-circle color-check' : 'fa-minus-circle') },
        text: step.text,
        styleClass: 'f-w-400'
      });
    });
    return steps;
  }

  private getRemediationsStepsProgressCounters = (remediation_groups: GenericObject<RemediationGroup>):
    { stepsDone: number, totalSteps: number } => {
    const groups = Object.keys(remediation_groups || {});
    const totalSteps = groups?.reduce((sum: number, group: string) => {
      return sum += remediation_groups[group]?.remediationsItems?.length || 0;
    }, 0);
    const stepsDone = groups?.reduce((sum: number, group: string) => {
      const totalItemDoneSteps = remediation_groups[group]?.remediationsItems?.reduce((total: number, item: Remediation) => {
        if (item.done) {
          return total += 1;
        }
        return total;
      }, 0);
      return sum += totalItemDoneSteps;
    }, 0);
    return { stepsDone, totalSteps };
  }

}
