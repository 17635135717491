import { Injectable } from '@angular/core';
import { AssetMetricsCardsConfigurations } from './configurations/asset-metrics.configuration';
import { TargetSummaryReportData } from 'app-models/target-summary/target-summary-report-data.model';
import { isEmpty } from 'lodash';
import { AssetCategoryConfig } from 'app-models/assets/asset-category.configuration';
import { deepCloneObject } from 'app-scripts/utilities/general';
import { TranslationService } from 'app-services/translation.service';
import { XYChart } from 'app-models/chart/xy-chart.model';

@Injectable({
  providedIn: 'root'
})
export class AssetMetricsService {

  constructor(
    private translation: TranslationService,
  ) { }

  getConfigurations = (reportData: TargetSummaryReportData) => {
    const config = deepCloneObject(AssetMetricsCardsConfigurations);
    config.topCards.forEach((card) => {
      card.config.description = reportData[card.name];
    });
    if (!isEmpty(reportData.topTechnologies)) {
      config.technologiesCard.xyChart = this.setXyChartConfig(config.technologiesCard.xyChart, reportData.topTechnologies);
    }
    return config;
  }

  getCategoriesIcons = (categories: TargetSummaryReportData['topCategories']) => {
    if (isEmpty(categories)) { return; }
    return categories.map((category) => {
      category.icon = `${AssetCategoryConfig.icons[category.key]}`;
    });
  }

  private setXyChartConfig = (xyChartConfig: XYChart, topTechnologies: TargetSummaryReportData['topTechnologies']) => {
    xyChartConfig.chart.data = topTechnologies;
    xyChartConfig.chart.options.serieses.forEach((series) => {
      switch (series.valueY) {
        case 'total':
          series.legendSettings = { labelText: this.translation.translate('total') };
          break;
        case 'outdated':
          series.legendSettings = { labelText: this.translation.translate('outdated') };
          break;
      }
    });
    return xyChartConfig;
  }
}
