
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { ChartOptions } from 'app-models/chart/chart-option.model';
import { SeriesType } from 'app-models/chart/series-type.enum';
import { isArray, isUndefined } from 'lodash';


export class ChartHelper {

  getSeries = (options: ChartOptions, chart?: am4charts.XYChart | am4charts.PieChart):
    am4charts.LineSeries | am4charts.XYSeries | am4charts.PieSeries => {
    let series: am4charts.LineSeries | am4charts.XYSeries | am4charts.PieSeries;

    switch (options.seriesType) {
      case SeriesType.Line:
        series = this.getLineSeries(options);
        break;
      case SeriesType.Bar:
        series = this.getXYSeries(options, chart as am4charts.XYChart);
        break;
      case SeriesType.Pie:
        series = this.getPieSeries(options);
        break;
      default:
        break;
    }

    if (!!options?.series?.name) {
      series.name = options.series.name;
    }

    if (!!options.tooltip?.zIndex) {
      series.tooltip.zIndex = options.tooltip?.zIndex;
    }

    if (!!options.tooltip?.fontSize) {
      series.tooltip.fontSize = options.tooltip?.fontSize;
    }

    if (!!options.legend?.labelText) {
      series.legendSettings.labelText = options.legend?.labelText;
    }

    if (!!options?.series?.value) {
      series.dataFields.value = options?.series?.value;
    }

    if (!!options.series?.title) {
      series.name = options.series?.title;
    }

    if (!!options?.series?.stroke) {
      series.stroke = am4core.color(options?.series?.stroke);
    }

    if (!!options?.series?.fill) {
      series.fill = am4core.color(options?.series?.fill);
    }

    if (!!options?.series?.fillTooltipFromObject) {
      series.tooltip.getFillFromObject = options?.series?.fillTooltipFromObject;
    }

    if (!!options?.series?.background) {
      series.tooltip.background.fill = am4core.color(options?.series?.background);
    }

    if (!!options?.series?.fillOpacity) {
      series.fillOpacity = options?.series?.fillOpacity;
    }

    return series;
  }

  private getXYSeries = (options: ChartOptions, chart?: am4charts.XYChart): am4charts.XYSeries => {
    const series = new am4charts.ColumnSeries();
    if (!!options?.series?.colorProperty) {

      series.columns.template.adapter.add('fill', (fill, target: any) => {
        return am4core.color(options?.series?.fill) || target.dataItem.dataContext?.color;
      });

      series.columns.template.adapter.add('stroke', (fill, target: any) => {
        return am4core.color(options?.series?.fill) || target.dataItem.dataContext?.color;
      });
    } else {
      series.columns.template.adapter.add('fill', (fill, target: any) => {
        return chart.colors.getIndex(target.dataItem.index);
      });
    }

    if (!isUndefined(options?.series?.valueY)) {
      series.dataFields.valueY = `${options?.series?.valueY}`;
    }

    if (!!options?.series?.categoryX) {
      series.dataFields.categoryX = options?.series?.categoryX;
    }

    const tooltipText = options?.tooltip?.text || options.tooltipText;
    if (!!tooltipText) {
      series.tooltipText = tooltipText;
    }

    if (!isUndefined(options?.series?.strokeWidth)) {
      series.strokeWidth = options?.series?.strokeWidth;
    }

    if (!isUndefined(options?.series?.labelBullet)) {
      const labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = options?.series?.labelBullet?.text;
      labelBullet.label.fontSize = options?.series?.labelBullet?.fontSize;
      labelBullet.label.truncate = options?.series?.labelBullet?.truncate;
      labelBullet.label.dy = -(options?.series?.labelBullet?.paddingY);
      labelBullet.label.fill = am4core.color(options?.series?.labelBullet?.fill);
    }

    series.columns.template.width = options?.series?.columns?.width;
    const cornerRadiusArray = options?.series?.columns?.cornerRadius;
    if (isArray(cornerRadiusArray) && cornerRadiusArray?.length === 4) {
      series.columns.template.column.cornerRadius(
        cornerRadiusArray[0],
        cornerRadiusArray[1],
        cornerRadiusArray[2],
        cornerRadiusArray[3]
      );
    }

    if (!!options?.series?.fill) {
      series.columns.template.fill = am4core.color(options?.series?.fill);
    }
    if (!!options?.series?.legendSettings?.labelText) {
      series.legendSettings.labelText = options?.series?.legendSettings.labelText;
    }
    return series;
  }

  private getLineSeries = (options: ChartOptions): am4charts.LineSeries => {
    const series = new am4charts.LineSeries();
    if (!!options?.series?.strokeWidth) {
      series.strokeWidth = options?.series?.strokeWidth;
    } else {
      series.strokeWidth = 3;
    }

    if (!!options?.series?.tensionX) {
      series.tensionX = options?.series?.tensionX;
    }

    if (!!options?.series?.tensionY) {
      series.tensionY = options?.series?.tensionY;
    }

    if (!!options?.series?.useMonotoneSmoothing) {
      series.smoothing = 'monotoneX';
    }

    if (!isUndefined(options?.series?.valueY)) {
      series.dataFields.valueY = `${options?.series?.valueY}`;
    }

    if (!!options?.series?.categoryX) {
      series.dataFields.categoryX = options?.series?.categoryX;
    }

    const tooltipText = options?.tooltip?.text || options.tooltipText;
    if (!!tooltipText) {
      series.tooltipText = tooltipText;
    }

    const fillModifier = new am4core.LinearGradientModifier();
    fillModifier.opacities = options?.fillModifier?.opacities;
    fillModifier.offsets = options?.fillModifier?.offsets;
    fillModifier.gradient.rotation = options?.fillModifier?.rotation;
    series.segments.template.fillModifier = fillModifier;

    return series;
  }

  private getPieSeries = (options: ChartOptions): am4charts.PieSeries => {
    const series = new am4charts.PieSeries();
    series.slices.template.propertyFields.fill = 'color';

    if (options?.disableChartTooltip) {
      series.slices.template.tooltipText = null;
    }

    if (!!options?.dataFields) {
      series.dataFields.value = options.dataFields.value;
      series.dataFields.category = options.dataFields.category;
    }

    series.labels.template.disabled = options?.series?.label?.disabled;
    series.ticks.template.disabled = options?.series?.ticks?.disabled;

    if (!!options?.hover) {
      series.slices.template.fillOpacity = 1;
      const hoverState = series.slices.template.states.getKey('hover');
      hoverState.properties.scale = 1;
      hoverState.properties.fillOpacity = options?.hover.opacity;
    }

    if (!!options?.series?.category) {
      series.dataFields.category = options?.series?.category;
    }

    const tooltipText = options?.tooltip?.text || options.tooltipText;
    if (!!tooltipText) {
      series.slices.template.tooltipText = tooltipText;
    }

    return series;
  }


  createPercentage = (percentage: number) => {
    return am4core.percent(percentage);
  }

}
