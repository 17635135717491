export enum TableColumnHeader {
  Category = 'category',
  AssetName = 'asset_name',
  Score = 'score',
  AssetTags = 'asset_tags',
  LastVulnerableDate = 'last_vulnerable_date',
  LastRemediatedDate = 'last_remediated_date',
  Technology = 'technology',
  Versions = 'versions',
  LastSeenDate = 'last_seen_date',
  Outdated = 'outdated',
  Name = 'name',
  Latest = 'latest',
  IPAddress = 'ip_address',
  Port = 'port',
  Details = 'details',
  Type = 'type',
  Information = 'information',
  Identifier = 'identifier',
}
