import { EnvironmentType } from './environment-type.enum';

export const environment = {
  env: EnvironmentType.DEV,
  production: false,
  mainUrl: 'https://report-manager-dev.mgnsecure.com/#/',
  apiUrl: 'https://report-manager-dev.mgnsecure.com/api/',
  loginUrl: 'https://auth-dev.mgnsecure.com/#/login',
  authUrl: 'https://auth-dev.mgnsecure.com/#/login?app=reportmanager&returnUrl=',
};
