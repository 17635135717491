import { Component, Input, OnInit } from '@angular/core';
import { ReportSectionBodyConfiguration } from 'app-models/report/report-section/report-section-body-configuration.model';
import { sortBy } from 'lodash';
import { ReportSectionBodyItemType } from 'app-models/report/report-section/report-section-body-item-type.enum';

@Component({
  selector: 'app-report-section-body',
  templateUrl: './report-section-body.component.html',
  styleUrls: ['./report-section-body.component.scss']
})
export class ReportSectionBodyComponent implements OnInit {

  @Input() configurations: ReportSectionBodyConfiguration[];
  readonly ReportSectionBodyItemType = ReportSectionBodyItemType;

  constructor() { }

  ngOnInit(): void {
    this.configurations = sortBy(this.configurations, 'order');
  }

}
