import { Component, Input, OnInit } from '@angular/core';
import { ListItemConfiguration } from 'app-models/list/list-item-configuration.model';
import { orderBy } from 'lodash';
import { ListItemType } from 'app-models/list/list-item-type.enum';
import { LayoutType } from 'app-models/common/layout-type.enum';
import { ProgressBarConfiguration } from 'app-models/progress-bar/progress-bar-configuration.model';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  readonly ListItem = ListItemType;
  readonly LayoutType = LayoutType;
  @Input() items: ListItemConfiguration[];
  @Input() progressBarConfiguration: ProgressBarConfiguration;

  constructor() { }

  ngOnInit(): void {
    this.items = orderBy(this.items, 'order');
  }

}
