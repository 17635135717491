import { Component, Input } from '@angular/core';
import { InfoIcon } from 'app-models/icon/info-icon.model';

@Component({
  selector: 'app-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent {
  @Input() data: InfoIcon;
  constructor() { }

}
