export enum FindingsReportSectionBodyItemIdentifier {
  Details = 'details',
  Steps = 'steps',
  Timeline = 'timeline',
  Remediations = 'remediations',
  TechnologyStack = 'technology_stack',
  IPAddressesAndPorts = 'ip_addresses_and_ports',
  Enrichments = 'enrichments',
  Notes = 'notes',
}
