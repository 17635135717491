import { Injectable } from '@angular/core';
import { isEmpty, isUndefined } from 'lodash';
import { AssetAnalysisReportData } from 'app-models/asset-analysis/asset-analysis-report-data.model';
import { PieChart } from 'app-models/chart/pie-chart.model';
import { XYChart } from 'app-models/chart/xy-chart.model';
import { GenericObject } from 'app-models/common/generic-object';
import { SummaryCard } from 'app-models/summary-card/summary-card.model';
import { TranslationService } from 'app-services/translation.service';
import { SummaryCardDescriptionParameter } from '../../../models/summary-card/summary-card-description-parameter.model';
import { ParameterFormatType } from '../../../models/summary-card/parameter-format-type.enum';
import { bigNumbersSuffix } from '../../../scripts/utilities/general';
import moment from 'moment';
import { SummaryCardData } from '../../../models/summary-card/summary-card-data.model';

@Injectable({
  providedIn: 'root'
})
export class SummaryCardsService {

  constructor(
    private translationService: TranslationService
  ) { }

  getConfigurations = (configuration: GenericObject<SummaryCard>, data: GenericObject<SummaryCardData>): SummaryCard[] => {
    if (isUndefined(data)) {
      return [];
    }
    Object.keys(configuration).forEach((key) => {
      this.buildSummaryCardPieChartConfiguration(
        configuration[key].pieChart,
        data[key]?.pieChartData?.total,
        data[key]?.pieChartData?.count,
        data[key]?.pieChartData?.description_parameters
      );
      this.buildSummaryCardBarChartConfiguration(data[key]?.barChartData, configuration[key].xyChart);
    });
    return Object.values(configuration);
  }

  private buildSummaryCardPieChartConfiguration =
    (chart: PieChart, total: number, count: number, descriptionParameters?: SummaryCardDescriptionParameter[]):
    void => {
    if ((!total || !count)) {
      if (!isUndefined(chart)) {
        chart.chart = undefined;
      }
      return;
    }
    if (!!chart?.config) {
      chart.config.coverage = {
        ...chart.config?.coverage,
        count: count || 0,
        total: total || 0,
      };
      if (chart.config.description.text && chart.config.description.needsTranslation) {
        chart.config.description.parameters = [];
        descriptionParameters?.forEach((param) => {
          const parameterValue = this.getFormatedParameterValue(param);
          chart.config.description.parameters.push(parameterValue);
        });
      }
    }
  }

  private buildSummaryCardBarChartConfiguration = (chartData: any, chart: XYChart): void => {
    if (isEmpty(chartData)) {
      if (!isUndefined(chart)) {
        chart.chart = undefined;
      }
      return;
    }
    chartData?.forEach((dataItem) => {
      if (!!dataItem.category) {
        dataItem.category = this.translationService.translate(dataItem.category);
      }
    });
    chart.chart.options.title = this.translationService.translate(chart.chart.options.title);
    chart.chart.data = chartData;
  }

  private getFormatedParameterValue = (parameter: SummaryCardDescriptionParameter): string => {
    if (isUndefined(parameter?.format)) {
      return parameter?.value || '';
    }
    switch (parameter.format.type) {
      case ParameterFormatType.BigNumbersSuffix:
        return bigNumbersSuffix(Number(parameter.value));
      case ParameterFormatType.Date:
        return moment(Number(parameter.value)).format(parameter.format.value || 'MMM DD YYYY');
      default:
        return parameter?.value || '';
    }
  }

}
