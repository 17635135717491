import { Component, Input, OnInit } from '@angular/core';
import { TargetSummaryConfig } from 'app-models/target-summary/target-summary-configuration.model';
import { TargetSummaryReportData } from 'app-models/target-summary/target-summary-report-data.model';
import { isEmpty } from 'lodash';
import { VectorMetricsService } from './vector-metrics.service';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';
import { ChartService } from 'app-components/chart/chart.service';
import { ChartTypeEnum } from 'app-models/chart/chart-type.enum';

@Component({
  selector: 'app-vector-metrics',
  templateUrl: './vector-metrics.component.html',
  styleUrls: ['./vector-metrics.component.scss']
})
export class VectorMetricsComponent implements OnInit {
  @Input() reportData: TargetSummaryReportData;
  configurations: TargetSummaryConfig['vectorMetrics'];
  vectorPrioritiesTableData: any[];

  constructor(
    private vectorMetricsService: VectorMetricsService,
    private chartService: ChartService,
  ) { }

  ngOnInit(): void {
    this.configurations = this.vectorMetricsService.getConfigurations(this.reportData);
    this.vectorPrioritiesTableData = this.vectorMetricsService.buildHighestPriorityVectorsTableData(this.reportData.highestPriorityVectors);
    this.initializeVectorsPriorityChart();
  }

  private initializeVectorsPriorityChart = (): void => {
    setTimeout(() => {
      const chartData = this.configurations.vectorsByPriority.pieChart?.chart?.data;
      const chartId = this.configurations.vectorsByPriority.pieChart?.chart?.id;
      const chartOptions = this.configurations.vectorsByPriority.pieChart?.chart?.options;
      if (chartData) {
        this.chartService.initChart(chartData, ChartTypeEnum.Pie, chartId, chartOptions);
      }
    });
  }

}
