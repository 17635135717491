import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { XYChart } from 'app-models/chart/xy-chart.model';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';

@Component({
  selector: 'app-xy-chart',
  templateUrl: './xy-chart.component.html',
  styleUrls: ['./xy-chart.component.scss']
})
export class XYChartComponent implements OnInit {

/*
  This component is for Line & bar charts.
*/

  @Input() xyChart: XYChart;
  @Output() notify: EventEmitter<Notification> = new EventEmitter<Notification>();

  constructor() { }

  ngOnInit(): void {
    this.notify.emit({
      type: NotificationTypeEnum.Ready,
      data: this.xyChart?.chart?.type,
    });
  }
}
