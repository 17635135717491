import { ReportSectionConfiguration } from 'app-models/report/report-section/report-section-configuration.model';
import { Text } from 'app-models/common/text.model';
import { ReportSectionBodyItemType } from 'app-models/report/report-section/report-section-body-item-type.enum';
import { FindingsReportSectionBodyItemIdentifier } from 'app-models/report/report-section/findings-report-section-body-item-identifier.enum';
import { AssetEnrichmentsTableConfiguration, AssetIpsAndPortsTableConfiguration, AssetTechnologyStackTableConfiguration } from './asset-tables.configuration';
import { ProgressBarType } from 'app-models/progress-bar/progress-bar-type.enum';
import { ProgressBarMode } from 'app-models/progress-bar/progress-bar-mode.enum';
import { ProgressBarConfiguration } from 'app-models/progress-bar/progress-bar-configuration.model';

export const AssetFindingsSectionConfiguration: ReportSectionConfiguration = {
  header: {
    icon: {
      class: 'color-primary-pink f-s-22 f-w-500',
      backgroundColor: '#eb1c6229'
    },
    subtitle: {
      text: new Text('asset_details', true),
      styleClass: 'color-default-grey f-s-20'
    },
    title: {
      styleClass: 'color-primary-pink f-s-26 bold'
    }
  },
  body: [
    {
      type: ReportSectionBodyItemType.List,
      identifier: FindingsReportSectionBodyItemIdentifier.Details,
      title: {
        text: new Text('asset_details', true),
        styleClass: 'color-primary-pink f-2-16 bold'
      },
      order: 1,
    },
    {
      type: ReportSectionBodyItemType.Table,
      identifier: FindingsReportSectionBodyItemIdentifier.IPAddressesAndPorts,
      title: {
        text: new Text('ip_addresses_and_ports', true),
        styleClass: 'color-primary-pink f-2-16 bold'
      },
      order: 2,
      configuration: AssetIpsAndPortsTableConfiguration,
    },
    {
      type: ReportSectionBodyItemType.Table,
      identifier: FindingsReportSectionBodyItemIdentifier.Enrichments,
      title: {
        text: new Text('enrichments', true),
        styleClass: 'color-primary-pink f-2-16 bold'
      },
      order: 3,
      configuration: AssetEnrichmentsTableConfiguration,
    },
    {
      type: ReportSectionBodyItemType.Timeline,
      identifier: FindingsReportSectionBodyItemIdentifier.Timeline,
      title: {
        text: new Text('asset_timeline', true),
        styleClass: 'color-primary-pink f-2-16 bold'
      },
      order: 4
    },
    {
      type: ReportSectionBodyItemType.Text,
      identifier: FindingsReportSectionBodyItemIdentifier.Notes,
      title: {
        text: new Text('asset_notes', true),
        styleClass: 'color-primary-pink f-2-16 bold'
      },
      order: 5
    },
    {
      type: ReportSectionBodyItemType.List,
      identifier: FindingsReportSectionBodyItemIdentifier.Remediations,
      title: {
        text: new Text('remediation_steps', true),
        styleClass: 'color-primary-pink f-2-16 bold'
      },
      order: 6,
      configuration: {
        // Hide until furthur notice as part of hh-8919
        // progressBarConfiguration: {
        //   type: ProgressBarType.Continues,
        //   showValue: false,
        //   mode: ProgressBarMode.Determinate,
        //   title: new Text('steps_done', true),
        //   unit: '',
        //   class: 'list-progressbar',
        // } as ProgressBarConfiguration
      }
    },
    {
      type: ReportSectionBodyItemType.Table,
      identifier: FindingsReportSectionBodyItemIdentifier.TechnologyStack,
      title: {
        text: new Text('technology_stack', true),
        styleClass: 'color-primary-pink f-2-16 bold'
      },
      order: 7,
      configuration: AssetTechnologyStackTableConfiguration
    },
  ]
};
