import { Component, Input, OnInit } from '@angular/core';
import { Image } from 'app-configurations/images.configuration';
import { ImageService } from 'app-services/image.service';
import { LogoConfiguration } from '../../models/logo/logo-configuration.model';
import { LayoutType } from '../../models/common/layout-type.enum';
import { isUndefined } from 'lodash';

@Component({
  selector: 'app-ultrared-logo',
  templateUrl: './ultrared-logo.component.html',
  styleUrls: ['./ultrared-logo.component.scss']
})
export class UltraredLogoComponent implements OnInit {

  @Input() configuration: LogoConfiguration;
  image: string;
  LogoLayoutType = LayoutType;

  constructor(
    private imageService: ImageService
    ) { }

  ngOnInit(): void {
    if (isUndefined(this.configuration.layout)) {
      this.configuration.layout = LayoutType.Vertical;
    }
  }


}
