import { ChartTypeEnum } from 'app-models/chart/chart-type.enum';
import { SeriesType } from 'app-models/chart/series-type.enum';
import { Text } from 'app-models/common/text.model';
import { TargetDashboardConfig } from 'app-models/dashboard/dashboard-configuration.model';
import { ScoreKnobColors } from 'configurations/asset-score.configuration';
import { VirtualScrollerDataTypeEnum } from 'app-models/virtual-scroller-container/virtual-scroller-type.enum';
import { Priority } from 'app-models/opportunity/priority.enum';

export const TargetDashboardConfigurations: TargetDashboardConfig = {
  cards: {
    monitoredAssets: {
      type: ChartTypeEnum.Pie,
      pieChart: {
        config: {
          subtitle: {
            text: new Text('dashboard_view.cards.monitored_assets.subtitle', true),
          }
        },
        chart: {
          id: 'pieChart',
          type: ChartTypeEnum.Pie,
          options: {
            seriesType: SeriesType.Pie,
            dataFields: {
              value: 'value',
              category: 'key'
            },
            tooltip: {
              fontSize: 12,
              zIndex: Infinity,
            },
            series: {
              label: {
                disabled: true,
              },
            },
          }
        }
      },
      config: {
        containerClass: 'monitored-assets-card',
        routerLink: '../discovery',
        styleClass: 'card h-inherit w-inherit cursor-pointer'
      },
    },
    vectors: {
      type: ChartTypeEnum.Line,
      xyChart: {
        chart: {
          id: 'xyChart',
          type: ChartTypeEnum.Line,
          class: 'xy-chart',
          tooltip: 'dashboard_view.cards.vectors.tooltip',
          options: {
            titleMarginBottom: 30,
            tooltip: {
              zIndex: 9999,
            },
            seriesType: SeriesType.Line,
            categoryAxis: {
              category: 'day',
              hidden: true,
              tooltip: {
                disabled: true,
              }
            },
            title: 'dashboard_view.cards.vectors.subtitle',
            valueAxis: {
              tooltip: {
                disabled: true,
              },
            },
            legend: {
              useDefaultMarker: true,
              fontSize: 14,
              fontColor: '#FFFFFF'
            },
            serieses: [
              {
                title: `priorities.${Priority.Informational}`,
                valueY: Priority.Informational,
                categoryX: 'day',
                strokeWidth: 1,
                tooltipText: '{categoryX} \n [bold]+{valueY < 1 : 0 : valueY} vector(s)[/]',
                stroke: ScoreKnobColors[Priority.Informational],
                fill: ScoreKnobColors[Priority.Informational],
                fillTooltipFromObject: false,
                background: ScoreKnobColors[Priority.Informational],
              },
              {
                title: `priorities.${Priority.Low}`,
                valueY: Priority.Low,
                categoryX: 'day',
                strokeWidth: 1,
                tooltipText: '{categoryX} \n [bold]+{valueY} vector(s)[/]',
                stroke: ScoreKnobColors[Priority.Low],
                fill: ScoreKnobColors[Priority.Low],
                fillTooltipFromObject: false,
                background: ScoreKnobColors[Priority.Low],
              },
              {
                title: `priorities.${Priority.Medium}`,
                valueY: Priority.Medium,
                categoryX: 'day',
                strokeWidth: 1,
                tooltipText: '{categoryX} \n [bold]+{valueY} vector(s)[/]',
                stroke: ScoreKnobColors[Priority.Medium],
                fill: ScoreKnobColors[Priority.Medium],
                fillTooltipFromObject: false,
                background: ScoreKnobColors[Priority.Medium],
              },
              {
                title: `priorities.${Priority.High}`,
                valueY: Priority.High,
                categoryX: 'day',
                strokeWidth: 1,
                tooltipText: '{categoryX} \n [bold]+{valueY} vector(s)[/]',
                stroke: ScoreKnobColors[Priority.High],
                fill: ScoreKnobColors[Priority.High],
                fillTooltipFromObject: false,
                background: ScoreKnobColors[Priority.High],
              },
              {
                title: `priorities.${Priority.Critical}`,
                valueY: Priority.Critical,
                categoryX: 'day',
                strokeWidth: 1,
                tooltipText: '{categoryX} \n [bold]+{valueY} vector(s)[/]',
                stroke: ScoreKnobColors[Priority.Critical],
                fill: ScoreKnobColors[Priority.Critical],
                fillTooltipFromObject: false,
                background: ScoreKnobColors[Priority.Critical],
              }
            ],
            fillModifier: {
              opacities: [1, 0],
              offsets: [0, 1],
              rotation: 90,
            },
          },
        },
      },
      config: {
        containerClass: 'vectors',
        icon: {
          class: 'fa fa-bug f-s-30 color-primary-pink',
          background: 'card-icon-background d-flex justify-content-center align-items-center background-light-primary-pink',
        },
        title: {
          class: 'p-t-15 color-primary-pink',
          text: new Text('dashboard_view.cards.vectors.title', true),
        },
        description: {
          class: 'color-primary-pink bold p-t-19 card-icon',
        },
        styleClass: 'h-inherit w-inherit cursor-pointer',
      },
    },
    vulnerableAssets: {
      config: {
        containerClass: 'vulnerable-assets-card',
        routerLink: '../asset-management',
        icon: {
          class: 'fa fa-desktop f-s-30 color-primary-pink',
          background: 'card-icon-background d-flex justify-content-center align-items-center background-light-primary-pink',
        },
        title: {
          class: 'p-t-15 color-primary-pink',
          text: new Text('dashboard_view.cards.vulnerable_assets.title', true),
        },
        description: {
          class: 'color-primary-pink card-icon bold p-t-20',
        },
        styleClass: 'card h-inherit w-inherit cursor-pointer',
      },
    },
  },
  vectorsVirtualScroller: {
    type: VirtualScrollerDataTypeEnum.Vectors,
    config: {
      scrollerType: VirtualScrollerDataTypeEnum.Vectors,
      showDescription: true,
      router: {
        param: 'vectorId',
        route: '../vectors'
      },
      class: 'cursor-pointer'
    }
  },
};
