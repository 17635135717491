import { TimelineEntryType } from '../models/timeline/timeline-entry-type.enum';

export const TimelineIcons = {
  [TimelineEntryType.AccessedByAnOperator]: 'fal fa-laptop-code',
  [TimelineEntryType.VerifiedBySystem]: 'fal fa-check',
  [TimelineEntryType.AssociatedBySystem]: 'fal fa-user-cog',
  [TimelineEntryType.FoundBySystem]: 'fal fa-binoculars',
  [TimelineEntryType.AutomaticallyAssociated]: 'far fa-cogs',
  [TimelineEntryType.InsightAddedbyAnalyst]: 'far fa-user-chart',
  [TimelineEntryType.CommentedByAnOperator]: 'fal fa-comment-lines',
  [TimelineEntryType.Messages]: 'fal fa-comment-lines',
  [TimelineEntryType.IP]: 'fal fa-map-marker-alt',
  [TimelineEntryType.Domain]: 'far fa-browser',
  [TimelineEntryType.URL]: 'fal fa-globe',
  [TimelineEntryType.ScoreChangedByUser]: 'fal fa-tachometer-alt',
  [TimelineEntryType.AssetCreated]: 'far fa-sparkles',
  [TimelineEntryType.VectorAddedToAsset]: 'far fa-link',
  [TimelineEntryType.VectorRemovedFromAsset]: 'far fa-unlink',
  [TimelineEntryType.ScoreChangedByVector]: 'fal fa-tachometer-alt',
  [TimelineEntryType.AssetRemediated]: 'far fa-badge-check',
  [TimelineEntryType.RemediationMarkedAsDone]: 'far fa-clipboard-check',
  [TimelineEntryType.VectorArchived]: 'far fa-archive',
  [TimelineEntryType.VectorRiskAccepted]: 'far fa-check-circle',
  [TimelineEntryType.DetachedFromAsset]: 'far fa-unlink',
  [TimelineEntryType.AttachedToAsset]: 'far fa-link',
  [TimelineEntryType.AssetDiscovered]: 'far fa-lightbulb-on',
  [TimelineEntryType.SavedFromAssistant]: 'vita-icon',
  [TimelineEntryType.AssetAssigned]: 'far fa-user-plus',
  [TimelineEntryType.AssetUnassigned]: 'far fa-user-minus',
  [TimelineEntryType.VectorFlagged]: 'fas fa-flag',
  [TimelineEntryType.VectorUnflagged]: 'far fa-flag',
  [TimelineEntryType.OpenPortFound]: 'far fa-ethernet',
  [TimelineEntryType.PortStatusChanged]: 'far fa-ethernet',
  [TimelineEntryType.NewTechnologyFound]: 'far fa-bug',
  [TimelineEntryType.NewEnrichmentFound]: 'far fa-plus-hexagon',
  [TimelineEntryType.VectorValidationCompleted]: 'fab fa-creative-commons-sampling',
  [TimelineEntryType.VectorValidationInitiated]: 'fab fa-creative-commons-sampling',
  [TimelineEntryType.VectorValidationFailed]: 'fab fa-creative-commons-sampling',
  [TimelineEntryType.PortsRemovedFromAsset]: 'far fa-do-not-enter',
};
