export enum TimelineEntryType {
  AccessedByAnOperator = 'accessed_by_an_operator',
  VerifiedBySystem = 'verified_by_system',
  AssociatedBySystem = 'associated_by_system',
  FoundBySystem = 'found_by_system',
  AutomaticallyAssociated = 'automatically_associated',
  InsightAddedbyAnalyst = 'insight_added_by_analyst',
  CommentedByAnOperator = 'commented_by_an_operator',
  Messages = 'messages',
  IP = 'ip',
  Domain = 'domain',
  URL = 'url',
  ScoreChangedByUser = 'score_changed_by_user',
  VectorAddedToAsset = 'vector_added_to_asset',
  VectorRemovedFromAsset = 'vector_removed_from_asset',
  ScoreChangedByVector = 'score_changed_by_vector',
  AssetCreated = 'asset_created',
  AssetRemediated = 'asset_remediated',
  RemediationMarkedAsDone = 'remediation_marked_as_done',
  VectorArchived = 'vector_archived',
  VectorRiskAccepted = 'vector_risk_accepted',
  DetachedFromAsset = 'detached_from_asset',
  AttachedToAsset = 'attached_to_asset',
  AssetDiscovered = 'asset_discovered',
  SavedFromAssistant = 'saved_from_assistant',
  AssetAssigned = 'asset_assigned',
  AssetUnassigned = 'asset_unassigned',
  VectorFlagged = 'vector_flagged',
  VectorUnflagged = 'vector_unflagged',
  OpenPortFound = 'open_port_found',
  PortStatusChanged = 'port_status_changed',
  NewTechnologyFound = 'new_technology_found',
  NewEnrichmentFound = 'new_enrichment_found',
  VectorValidationCompleted = 'vector_validation_completed',
  VectorValidationInitiated = 'vector_validation_initiated',
  VectorValidationFailed = 'vector_validation_failed',
  PortsRemovedFromAsset = 'ports_removed_from_asset',
}
