import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'app-services/translation.service';
import { stringFormat } from 'app-scripts/utilities/general';

@Pipe({ name: 'translate', pure: false })

export class TranslatePipe implements PipeTransform {
  static instance: TranslatePipe;
  constructor(private translationService: TranslationService) {
    return TranslatePipe.instance = TranslatePipe.instance || this;
  }
  transform(value: any, translate?: boolean, params?: string[]): string {
    if (value === undefined || value === null || (value.isEmptyOrNullOrUndefined && value.isEmptyOrNullOrUndefined())) { return undefined; }
    if (translate !== undefined && !translate) {
      return value;
    }

    return this.translationService.translate(value, params);
  }
}
