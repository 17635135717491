import { Component, Input, OnInit } from '@angular/core';
import { GenericObject } from '../../../models/common/generic-object';
import { AppendixConfiguration } from '../../../models/appendix/appendix-confguration.model';
import { AssetAnalysisReportData } from '../../../models/asset-analysis/asset-analysis-report-data.model';

@Component({
  selector: 'app-asset-analysis-appendixes',
  templateUrl: './asset-analysis-appendixes.component.html',
  styleUrls: ['./asset-analysis-appendixes.component.scss']
})
export class AssetAnalysisAppendixesComponent implements OnInit {

  @Input() configuration: GenericObject<AppendixConfiguration>;
  @Input() data: AssetAnalysisReportData['appendixes'];
  appendixesKeys: (keyof AssetAnalysisReportData['appendixes'])[];

  constructor() { }

  ngOnInit(): void {
    this.appendixesKeys = Object.keys(this.data);
  }

}
