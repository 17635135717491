import { Injectable } from '@angular/core';

@Injectable()
export class ImageService {

    constructor() { }

    getImage = (image: any): any => {
        // tslint:disable-next-line:no-string-literal
        return image['default'] ? image['default'] : image;
    }
}
