import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApplicationDataStorageService } from 'app-services/applicationDataStorage.service';
import { getHttpRequestOptions } from 'app-scripts/utilities/general';
import { MgnHttpRequest } from 'app-models/common/httpRequest.model';
import { _throw } from 'rxjs/observable/throw';

// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs/observable/forkJoin';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from 'rxjs/operators';

@Injectable()
export class HttpRequestService {

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private applicationGlobalsService: ApplicationDataStorageService
  ) {
    getHttpRequestOptions();
  }

  public post = (url: string, body?: any): Observable<any> => {
    return this.httpClient.post(url, JSON.stringify(body), getHttpRequestOptions())
      .map((res: any) => {
        if (res === null) {
          return undefined;
        }
        return this.extractData(res);
      })
      .catch((error: any) => {
        return this.handleErrorObservable(error);
      });
  }

  public search = (url: string, body?: any): Observable<any> => {
    return this.httpClient.post(url, body, getHttpRequestOptions('search'))
      .map((res: any) => {
        if (res === null) {
          return undefined;
        }
        return this.extractData(res);
      })
      .catch((error: any) => {
        return this.handleErrorObservable(error);
      });
  }

  public put = (url: string, body?: any): Observable<any> => {
    return this.httpClient.put(url, JSON.stringify(body), getHttpRequestOptions())
      .map((res: any) => {
        if (res === null) {
          return undefined;
        }
        return this.extractData(res);
      })
      .catch((error: any) => {
        return this.handleErrorObservable(error);
      });
  }

  public delete = (url: string): Observable<any> => {
    return this.httpClient.delete(url, getHttpRequestOptions())
      .map((res: any) => {
        if (res === null) {
          return undefined;
        }
        return this.extractData(res);
      })
      .catch((error: any) => {
        return this.handleErrorObservable(error);
      });
  }

  public forkJoinRequests = (requests: MgnHttpRequest[]): Observable<any> => {
    const r = requests.map(request => {
      switch (request.type) {
        case 'post':
          if (request.body === undefined) {
            request.body = {};
          }
          return this.httpClient.post(request.url,
            JSON.stringify(request.body),
            getHttpRequestOptions());
        case 'get':
          return this.httpClient.get(request.url, getHttpRequestOptions());
      }
    });
    return this.forkJoin(r);
  }

  public forkJoin = (observables: Observable<any>[]): Observable<any> => {
    return forkJoin(observables)
      .map(results => {
        if (results === null) {
          return undefined;
        }
        return results.map((res: any) => {
          return this.extractData(res);
        });
      })
      .catch((error: any) => {
        return this.handleErrorObservable(error);
      });
  }

  public get = (url: string): Observable<any> => {
    return this.httpClient.get(url, getHttpRequestOptions()).pipe(
      map((res: any) => {
        if (res === null) {
          return undefined;
        }
        return this.extractData(res);
      },
        (error: any) => {
          return this.handleErrorObservable(error);
        })
    );
  }

  private extractData = (res: Response) => {
    return res;
  }

  private handleErrorObservable = (e: Response | any) => {
    if (e.status === 401) {
      const currentUrl = window.location.href;
      const authUrl = this.applicationGlobalsService.globalConfigurations.authUrl;
      window.location.href = authUrl + currentUrl;
    }
    if (e.status === 403) {
      this.router.navigate(['/unauthorized']);
    }
    return _throw(e);
  }
}
