import { AssetAnalysisConfiguration } from 'app-models/asset-analysis/asset-analysis-configuration.model';
import { SummaryCardsConfiguration } from './summary-cards.configuration';
import { AssetAnalysisOpeningPageConfiguration } from './asset-analysis-opening-page.configuration';
import { AssetAnalysisAppendixesConfiguration } from './asset-analysis-appendixes.configuration';

export const assetAnalysisConfiguration: AssetAnalysisConfiguration = {
  openingPage: AssetAnalysisOpeningPageConfiguration,
  summaryCards: SummaryCardsConfiguration,
  appendixes: AssetAnalysisAppendixesConfiguration,
};
