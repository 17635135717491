import { Pipe, PipeTransform } from '@angular/core';
import { normalizeUnixTimestamp } from 'app-scripts/utilities/general';

@Pipe({
  name: 'normalizeUnixTimestamp'
})
export class NormalizeUnixTimestamp implements PipeTransform {
  transform(val: any): any {
    if (!val) { return val; }
    return normalizeUnixTimestamp(val);
  }
}
