import { TableColumnHeader } from 'app-models/table/table-column-header.enum';
import { Text } from 'app-models/common/text.model';
import { TableCellType } from 'app-models/table/table-cell-type.enum';
import { AssetTechnology } from 'app-models/assets/asset-technology.model';
import { TableData } from 'app-models/table/table-data.model';

export const RelatedAssetTechnologyStackTableConfiguration: TableData<AssetTechnology> = {
  tableConfiguration: {
    styleClass: 'findings-table',
  },
  columns: [
    {
      field: 'outdated',
      headers: [{
        text: new Text(TableColumnHeader.Outdated, true),
      }],
      icon: {
        class: 'fal fa-calendar-exclamation f-w-500',
      },
      types: [TableCellType.Icon],
      showIf: ((technology) => !!technology.outdated),
    },
    {
      field: 'name',
      headers: [{
        text: new Text(TableColumnHeader.Name, true),
      }],
      types: [TableCellType.Text],
    },
    {
      field: 'versions',
      headers: [{
        text: new Text(TableColumnHeader.Versions, true),
      }],
      types: [TableCellType.Text],
    },
    {
      field: 'latest_version',
      headers: [{
        text: new Text(TableColumnHeader.Latest, true),
      }],
      types: [TableCellType.Text],
    },
  ]
};
