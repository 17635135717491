import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

// primeng
import { ChartModule } from 'primeng/chart';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ChipsModule } from 'primeng/chips';
import { CalendarModule } from 'primeng/calendar';
import { MenuModule } from 'primeng/menu';
import { CardModule } from 'primeng/card';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { SidebarModule } from 'primeng/sidebar';
import { TimelineModule } from 'primeng/timeline';
import { ChipModule } from 'primeng/chip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressBarModule } from 'primeng/progressbar';
import { TerminalModule } from 'primeng/terminal';
import { RippleModule } from 'primeng/ripple';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { BadgeModule } from 'primeng/badge';
import { GalleriaModule } from 'primeng/galleria';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DragDropModule } from 'primeng/dragdrop';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { KnobModule } from 'primeng/knob';
import { DividerModule } from 'primeng/divider';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// services
import { ApplicationLoadService } from 'app-services/applicationLoad.service';
import { ApplicationDataStorageService } from 'app-services/applicationDataStorage.service';
import { ApplicationConfigurationsService } from 'app-services/applicationConfigurations.service';
import { UrlService } from 'app-services/url.service';
import { UserService } from 'app-services/user.service';
import { HttpRequestService } from 'app-services/httpRequest.service';
import { ApplicationDynamicRoutes } from 'app-services/applicationDynamicRoutes.service';
import { ImageService } from 'app-services/image.service';

// components
import { AppComponent } from './app.component';
import { MainLayoutComponent } from 'app-layouts/main/main.layout';
import { ErrorsLayoutComponent } from 'app-layouts/errors/errors-layout/errors-layout';
import { UnauthorizedPageComponent } from 'app-pages/unauthorized/unauthorized.page';
import { BasePageComponent } from 'app-pages/base-page/base.page';
import { TranslatePipe } from 'app-pipes/translate.pipe';
import { TranslationService } from 'app-services/translation.service';
import { LocalizationService } from 'app-services/localization.service';
import { BaseCardComponent } from 'app-components/base-card/card.component';
import { ChartComponent } from 'app-components/chart/chart.component';
import { PieChartComponent } from 'app-components/charts/pie-chart/pie-chart.component';
import { XYChartComponent } from 'app-components/charts/xy-chart/xy-chart.component';
import { ForceDirectedV2Component } from 'app-components/charts/force-directed-v2/force-directed-v2.component';
import { AddBigNumbersSuffix } from 'app-pipes/big-numbers-suffix.pipe';
import { AddCommasPipe } from 'app-pipes/addCommas.pipe';
import { AppTitleCasePipe } from 'app-pipes/appTitleCase.pipe';
import { InfoBoxComponent } from 'app-components/info-box/info-box.component';
import { KnobComponent } from 'app-components/knob/knob.component';
import { InfoIconComponent } from 'app-components/info-icon/info-icon.component';
import { UltraredLogoComponent } from 'app-components/ultrared-logo/ultrared-logo.component';
import { SummaryCardComponent } from 'app-components/summary-card/summary-card.component';
import { EmptyStateComponent } from 'app-components/empty-state/empty-state.component';
import { TableComponent } from 'app-components/tables/table/table.component';
import { TableCellComponent } from 'app-components/tables/table-cell/table-cell.component';
import { NormalizeUnixTimestamp } from 'app-pipes/normalize-unix-timestamp.pipe';
import { TableAppendixComponent } from 'app-components/table-appendix/table-appendix.component';
import { ListComponent } from 'app-components/list/list.component';
import { TimelineComponent } from 'app-components/timeline/timeline.component';
import { ProgressBarComponent } from 'app-components/progress-bar/progress-bar.component';
import { SafeHtmlPipe } from 'app-pipes/safeHtml.pipe';
import { FindingsComponent } from 'app-components/findings/findings.component';
import { AssetMetricsComponent } from 'app-components/asset-metrics/asset-metrics.component';
import { VectorMetricsComponent } from 'app-components/vector-metrics/vector-metrics.component';

// reports
import { BaseReportComponent } from 'app-reports/base-report/base-report.component';
import { TargetDashboardReportComponent } from 'app-reports/target-dashboard/target-dashboard.report';
import { AssetAnalysisReportComponent } from 'app-reports/asset-analysis/asset-analysis.component';
import { SummaryCardsComponent } from 'app-reports/asset-analysis/summary-cards/summary-cards.component';
import { AssetAnalysisAppendixesComponent } from 'app-reports/asset-analysis/appendixes/asset-analysis-appendixes.component';
import { OpeningPageV2Component } from 'app-reports/opening-page/opening-page.component';
import { VectorFindingsReportComponent } from 'app-reports/vector-findings/vector-findings.component';
import { ReportSectionHeaderComponent } from 'app-reports/report-section/report-section-header/report-section-header.component';
import { ReportSectionBodyComponent } from 'app-reports/report-section/report-section-body/report-section-body.component';
import { AssetFindingsReportComponent } from 'app-reports/asset-findings/asset-findings.component';
import { TargetSummaryReportComponent } from 'app-reports/target-summary/target-summary.component';

require('../externals');

export function initApplication(appLoadService: ApplicationLoadService): any {
  return () => appLoadService.initApplication();
}

@NgModule({
  declarations: [
    // components
    AppComponent,
    MainLayoutComponent,
    ErrorsLayoutComponent,
    UnauthorizedPageComponent,
    BasePageComponent,
    TranslatePipe,
    AddBigNumbersSuffix,
    AddCommasPipe,
    AppTitleCasePipe,
    BaseCardComponent,
    ChartComponent,
    PieChartComponent,
    XYChartComponent,
    ForceDirectedV2Component,
    InfoBoxComponent,
    KnobComponent,
    InfoIconComponent,
    UltraredLogoComponent,
    SummaryCardComponent,
    EmptyStateComponent,
    TableComponent,
    TableCellComponent,
    NormalizeUnixTimestamp,
    TableAppendixComponent,
    ListComponent,
    TimelineComponent,
    ProgressBarComponent,
    SafeHtmlPipe,
    FindingsComponent,
    AssetMetricsComponent,
    VectorMetricsComponent,

    // reports
    BaseReportComponent,
    TargetDashboardReportComponent,
    AssetAnalysisReportComponent,
    SummaryCardsComponent,
    AssetAnalysisAppendixesComponent,
    OpeningPageV2Component,
    VectorFindingsReportComponent,
    ReportSectionHeaderComponent,
    ReportSectionBodyComponent,
    AssetFindingsReportComponent,
    TargetSummaryReportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HighlightModule,

    // primeNG
    ChartModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    CheckboxModule,
    DialogModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
    TooltipModule,
    TableModule,
    ToastModule,
    RadioButtonModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    InputTextareaModule,
    ChipsModule,
    CalendarModule,
    MenuModule,
    CardModule,
    DynamicDialogModule,
    SidebarModule,
    TimelineModule,
    ChipModule,
    InputSwitchModule,
    ProgressBarModule,
    TerminalModule,
    RippleModule,
    TabViewModule,
    MultiSelectModule,
    BadgeModule,
    GalleriaModule,
    SelectButtonModule,
    DragDropModule,
    OverlayPanelModule,
    SplitButtonModule,
    KnobModule,
    DividerModule,
    TriStateCheckboxModule,
  ],
  providers: [
    ApplicationDataStorageService,
    ApplicationConfigurationsService,
    UrlService,
    UserService,
    ApplicationLoadService,
    HttpRequestService,
    ApplicationDynamicRoutes,
    TranslationService,
    LocalizationService,
    ImageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      deps: [ApplicationLoadService],
      multi: true
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          http: () => import('highlight.js/lib/languages/http'),
          html: () => import('highlight.js/lib/languages/xml'),
        }
      }
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
