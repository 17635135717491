import { ReportSectionConfiguration } from 'app-models/report/report-section/report-section-configuration.model';
import { Text } from 'app-models/common/text.model';
import { ReportSectionBodyItemType } from 'app-models/report/report-section/report-section-body-item-type.enum';
import { FindingsReportSectionBodyItemIdentifier } from 'app-models/report/report-section/findings-report-section-body-item-identifier.enum';
import { ProgressBarType } from 'app-models/progress-bar/progress-bar-type.enum';
import { ProgressBarMode } from 'app-models/progress-bar/progress-bar-mode.enum';
import { ProgressBarConfiguration } from 'app-models/progress-bar/progress-bar-configuration.model';
import { RelatedAssetTechnologyStackTableConfiguration } from './related-asset-tables.configuration';

export const RelatedAssetSectionConfiguration: ReportSectionConfiguration = {
  header: {
    icon: {
      class: 'far fa-key color-primary-pink f-s-22 f-w-500',
      backgroundColor: '#eb1c6229'
    },
    subtitle: {
      text: new Text('asset_details', true),
      styleClass: 'color-default-grey f-s-20'
    },
    title: {
      styleClass: 'color-primary-pink f-s-26 bold'
    }
  },
  body: [
    {
      type: ReportSectionBodyItemType.List,
      identifier: FindingsReportSectionBodyItemIdentifier.Remediations,
      title: {
        text: new Text('remediation_steps', true),
        styleClass: 'color-primary-pink f-2-16 bold'
      },
      order: 1,
      configuration: {
        // Hide until furthur notice as part of hh-8919
        // progressBarConfiguration: {
        //   type: ProgressBarType.Continues,
        //   showValue: false,
        //   mode: ProgressBarMode.Determinate,
        //   title: new Text('steps_done', true),
        //   unit: '',
        //   class: 'list-progressbar',
        // } as ProgressBarConfiguration
      }
    },
    {
      type: ReportSectionBodyItemType.Table,
      identifier: FindingsReportSectionBodyItemIdentifier.TechnologyStack,
      title: {
        text: new Text('technology_stack', true),
        styleClass: 'color-primary-pink f-2-16 bold'
      },
      order: 4,
      configuration: RelatedAssetTechnologyStackTableConfiguration
    },
  ]
};
