import { Injectable } from '@angular/core';
import { FindingsConfiguration } from 'app-models/report/findings-configuration.model';
import { VectorFindingsOpeningPageConfiguration } from './configurations/vector-findings-opening-page.configuration';
import { deepCloneObject } from 'app-scripts/utilities/general';
import { VectorFindingsSectionConfiguration } from './configurations/vector-findings-section.configuration';
import { VectorFindingsReportData } from 'app-models/vector-findings/vector-findings-report-data.model';
import { Text } from 'app-models/common/text.model';
import { has, isEmpty } from 'lodash';
import { FindingsReportSectionBodyItemIdentifier } from 'app-models/report/report-section/findings-report-section-body-item-identifier.enum';
import { RelatedAssetSectionConfiguration } from './configurations/related-asset-section.configuraiton';
import { TimelineService } from 'app-components/timeline/timeline.service';
import { FindingsReportHelper } from 'app-helpers/reports/findings.helper';
import { Indicator } from 'app-models/indicator/indicator.mode';
import { Vector } from 'app-models/vectors/vector.model';

@Injectable({
  providedIn: 'root'
})
export class VectorFindingsReportService {

  constructor(
    private timelineService: TimelineService,
    private findingsHelper: FindingsReportHelper,
  ) { }

  getConfigurations = (report_request_date: number, target_name: string, data: VectorFindingsReportData): FindingsConfiguration => {
    const configurations: FindingsConfiguration = {
      openingPage: deepCloneObject(VectorFindingsOpeningPageConfiguration),
      findings_sections: [],
    };
    configurations.openingPage.date = report_request_date;
    configurations.openingPage.targetName = target_name;

    data?.finding_items?.forEach((item) => {
      item.vectors?.forEach((vector) => {
        const sectionConfiguration = deepCloneObject(VectorFindingsSectionConfiguration);
        sectionConfiguration.header.subtitle.text = new Text('related_asset', true, [item.related_asset.name]);
        sectionConfiguration.header.title.text = new Text(vector.tags[0], false);
        sectionConfiguration.header.indicators = this.filterIndicators(sectionConfiguration.header.indicators, vector);
        sectionConfiguration.body?.forEach((bodyItem, index) => {
          switch (bodyItem.identifier) {
            case FindingsReportSectionBodyItemIdentifier.Details: {
              sectionConfiguration.body[index].data = this.findingsHelper.buildVectorDetailsList(vector);
              break;
            }
            case FindingsReportSectionBodyItemIdentifier.Steps: {
              sectionConfiguration.body[index].data = this.findingsHelper.buildVectorSteps(vector);
              break;
            }
            case FindingsReportSectionBodyItemIdentifier.Timeline: {
              sectionConfiguration.body[index].data = this.timelineService.buildCommentsTimeline(vector.timeline);
              break;
            }
          }
        });
        configurations.findings_sections.push(sectionConfiguration);
      });
      const relatedAssetData = item.related_asset?.data;
      if (!isEmpty(relatedAssetData)) {
        const relatedAssetSectionConfiguration = deepCloneObject(RelatedAssetSectionConfiguration);
        relatedAssetSectionConfiguration.header.title.text = new Text(item.related_asset?.name, false);
        relatedAssetSectionConfiguration.body?.forEach((bodyItem, index) => {
          switch (bodyItem.identifier) {
            case FindingsReportSectionBodyItemIdentifier.Remediations: {
              relatedAssetSectionConfiguration.body[index].data = this.findingsHelper.
                buildAssetRemediationGroups(relatedAssetData?.remediation_groups);
              this.findingsHelper.buildStepsProgressBar(relatedAssetData?.remediation_groups,
                relatedAssetSectionConfiguration.body[index].configuration?.progressBarConfiguration);
              break;
            }
            case FindingsReportSectionBodyItemIdentifier.TechnologyStack: {
              relatedAssetSectionConfiguration.body[index].data = relatedAssetData?.technology_stack;
            }
          }
        });
        configurations.findings_sections.push(relatedAssetSectionConfiguration);
      }
    });
    return configurations;
  }

  private filterIndicators = (indicators: Indicator[], vector: Vector): Indicator[] => {
    return indicators.filter((indicator) => !has(indicator, 'showIf') || indicator.showIf(vector));
  }

}
