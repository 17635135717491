import { Component } from '@angular/core';
import { ReportType } from 'app-models/report/report-type.enum';
import { Report } from 'app-models/report/report.model';
import { FindingsConfiguration } from 'app-models/report/findings-configuration.model';
import { VectorFindingsReportData } from 'app-models/vector-findings/vector-findings-report-data.model';
import { VectorFindingsReportService } from './vector-findings.service';

@Component({
  selector: 'app-vector-findings',
  templateUrl: './vector-findings.component.html',
  styleUrls: ['./vector-findings.component.scss']
})
export class VectorFindingsReportComponent {
  readonly ReportType = ReportType;
  reportData: VectorFindingsReportData;
  configurations: FindingsConfiguration;

  constructor(
    private vectorFindingsReportService: VectorFindingsReportService)
  { }

  onReportData = (report: Report<VectorFindingsReportData>) => {
    this.reportData = report.data;
    this.configurations = this.vectorFindingsReportService
      .getConfigurations(report.request_date, report.metadata?.target_name, this.reportData);
  }

}
