import { TargetAsset } from 'app-models/assets/target-asset.model';
import { Text } from 'app-models/common/text.model';
import { TableColumnHeader } from 'app-models/table/table-column-header.enum';
import { TableCellType } from 'app-models/table/table-cell-type.enum';
import { TableData } from 'app-models/table/table-data.model';
import { AssetEnrichemnt } from 'app-models/assets/asset-enrichment.model';
import { AssetTechnology } from 'app-models/assets/asset-technology.model';

export const AssetIpsAndPortsTableConfiguration: TableData<TargetAsset> = {
  tableConfiguration: {
    styleClass: 'findings-table',
  },
  columns: [
    {
      field: 'address',
      headers: [{
        text: new Text(TableColumnHeader.IPAddress, true),
      }],
      types: [TableCellType.Text],
    },
    {
      field: 'port',
      headers: [{
        text: new Text(TableColumnHeader.Port, true),
      }],
      types: [TableCellType.Text],
    },
    {
      field: 'details',
      headers: [{
        text: new Text(TableColumnHeader.Details, true),
      }],
      needsTranslation: true,
      types: [TableCellType.Text],
    },
  ]
};

export const AssetEnrichmentsTableConfiguration: TableData<AssetEnrichemnt> = {
  tableConfiguration: {
    styleClass: 'findings-table',
  },
  columns: [
    {
      field: 'type',
      headers: [{
        text: new Text(TableColumnHeader.Type, true),
      }],
      types: [TableCellType.Text],
      width: '30%',
      alignTop: true,
    },
    {
      field: 'description',
      headers: [{
        text: new Text(TableColumnHeader.Information, true),
      }],
      types: [TableCellType.Text],
      width: '70%',
    },
  ]
};

export const AssetTechnologyStackTableConfiguration: TableData<AssetTechnology> = {
  tableConfiguration: {
    styleClass: 'findings-table',
  },
  columns: [
    {
      field: 'outdated',
      headers: [{
        text: new Text(TableColumnHeader.Outdated, true),
      }],
      icon: {
        class: 'fal fa-calendar-exclamation f-w-500',
      },
      types: [TableCellType.Icon],
      showIf: ((technology) => !!technology.outdated),
    },
    {
      field: 'name',
      headers: [{
        text: new Text(TableColumnHeader.Name, true),
      }],
      types: [TableCellType.Text],
    },
    {
      field: 'versions',
      headers: [{
        text: new Text(TableColumnHeader.Versions, true),
      }],
      types: [TableCellType.Text],
    },
    {
      field: 'latest_version',
      headers: [{
        text: new Text(TableColumnHeader.Latest, true),
      }],
      types: [TableCellType.Text],
    },
  ]
};
