import { Component, Input } from '@angular/core';
import { Chart } from 'app-models/chart/chart-configurations.model';

@Component({
  selector: 'app-force-directed-v2',
  templateUrl: './force-directed-v2.component.html',
  styleUrls: ['./force-directed-v2.component.scss']
})
export class ForceDirectedV2Component {

  @Input() chart: Chart;

  constructor() { }

}
