import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notification } from 'app-models/notification/notification.model';
import { TableConfiguration } from 'app-models/table/table-configuration.model';
import { TableCellConfiguration } from 'app-models/table/table-cell-configuration.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {

  @Input() data: any[];
  @Input() columns: TableCellConfiguration<any>[];
  @Input() config: TableConfiguration;
  @Input() total: number;
  @Output() notification: EventEmitter<Notification> = new EventEmitter();

  constructor() { }

}
