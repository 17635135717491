import { Component, Input } from '@angular/core';
import { ChartTypeEnum } from 'app-models/chart/chart-type.enum';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';
import { SummaryCard } from 'app-models/summary-card/summary-card.model';
import { ChartService } from '../chart/chart.service';

@Component({
  selector: 'app-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.scss']
})
export class SummaryCardComponent {

  @Input() configuration: SummaryCard;

  constructor(
    private chartService: ChartService
  ) { }

  notificationRecieved = (notification: Notification) => {
    switch (notification.type) {
      case NotificationTypeEnum.Ready: {
        this.initializeChart(notification.data);
        break;
      }
      default:
        break;
    }
  }


  private initializeChart = (chartType: ChartTypeEnum): void => {
    setTimeout(() => {
      const key = this.getConfigurationKeyByChartType(chartType);
      const chartData = this.configuration[key]?.chart?.data;
      const chartId = this.configuration[key]?.chart?.id;
      const chartOptions = this.configuration[key]?.chart?.options;
      if (!!key && chartData) {
        this.chartService.initChart(chartData, chartType, chartId, chartOptions);
      }
    });
  }

  private getConfigurationKeyByChartType = (chartType: ChartTypeEnum) => {
    let key: string;
    switch (chartType) {
      case ChartTypeEnum.Pie: {
        key = 'pieChart';
        break;
      }
      case ChartTypeEnum.Bar: {
        key = 'xyChart';
        break;
      }
      default:
        break;
    }
    return key;
  }
}
