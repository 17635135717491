import { TableColumnHeader } from 'app-models/table/table-column-header.enum';

export const TableColumnHeaderIconConfiguration = {
  [TableColumnHeader.Category]: 'far fa-align-left',
  [TableColumnHeader.AssetName]: 'far fa-cubes',
  [TableColumnHeader.Score]: 'far fa-signal',
  [TableColumnHeader.AssetTags]: 'far fa-tags',
  [TableColumnHeader.LastVulnerableDate]: 'far fa-calendar-exclamation',
  [TableColumnHeader.LastRemediatedDate]: 'far fa-calendar-exclamation',
  [TableColumnHeader.Technology]: 'far fa-microchip',
  [TableColumnHeader.Versions]: 'far fa-code-branch',
  [TableColumnHeader.LastSeenDate]: 'far fa-calendar-exclamation',
  [TableColumnHeader.Identifier]: 'far fa-id-badge',
};
