import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'addCommas' })

export class AddCommasPipe implements PipeTransform {
  constructor() { }

  transform(value: string | number): string {
    if (value === undefined) { return; }
    return value.toString().addCommas();
  }
}
