export enum UserActionType {
  Edit = 'Edit',
  Delete = 'Delete',
  Settings = 'Settings',
  Add = 'Add',
  Remove = 'remove',
  Send = 'send',
  Expand = 'expand',
  ToggleOn = 'ToggleOn',
  ToggleOff = 'ToggleOff',
  Download = 'download',
  Done = 'done',
  Report = 'report',
  Undone = 'undone',
  Open = 'open',
  Close = 'close',
  Assign = 'assign',
  MarkAsRemediated = 'mark_as_remediated',
  Attach = 'attach',
  Terminate = 'terminate',
  Reject = 'reject',
  Accept = 'accept',
  Favorite = 'favorite',
  Archive = 'archive',
  UnArchive = 'unarchive',
  AcceptRisk = 'accept_risk',
  UnAcceptRisk = 'unaccept_risk',
  Help = 'help',
}
