import { Injectable } from '@angular/core';
import { AssetAnalysisConfiguration } from 'app-models/asset-analysis/asset-analysis-configuration.model';
import { assetAnalysisConfiguration } from './configurations/asset-analysis.configuration';

@Injectable({
  providedIn: 'root'
})
export class AssetAnalysisReportService {

  constructor() { }

  getConfigurations = (report_request_date: number, target_name: string): AssetAnalysisConfiguration => {
    const configurations: AssetAnalysisConfiguration = assetAnalysisConfiguration;
    configurations.openingPage.date = report_request_date;
    configurations.openingPage.targetName = target_name;
    return configurations;
  }
}
