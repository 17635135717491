
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { IndexHelperFactory } from 'app-factories/charts.factory';
import { ChartOptions } from 'app-models/chart/chart-option.model';
import { LegendPosition } from 'app-models/chart/legend-position.enum';
import { ChartHelper } from './chart.helper';

export class PieChartHelper implements IndexHelperFactory {

  private chartHelper = new ChartHelper();

  initChart = (data: any, id: string, options?: ChartOptions) => {
    const chart = am4core.create(id, am4charts.PieChart);
    chart.logo.__disabled = true;
    chart.data = data;

    chart.innerRadius = am4core.percent(75);
    chart.radius = am4core.percent(90);
    const series: am4charts.PieSeries = this.chartHelper.getSeries(options) as am4charts.PieSeries;

    chart.series.push(series);

    if (!!options?.legend) {
      chart.legend = new am4charts.Legend();

      chart.legend.position = options.legend.position as any || LegendPosition.Bottom;
      chart.legend.labels.template.fill = am4core.color(options.legend.fontColor || 'white');
      chart.legend.fontSize = options.legend.fontSize || 10;
      chart.legend.maxWidth = options.legend.maxWidth || 150;
      chart.legend.useDefaultMarker = options.legend.useDefaultMarker;
      chart.legend.scrollable = true;
      chart.legend.contentAlign = options.legend.contentAlign;
      chart.legend.valueLabels.template.disabled = options.legend.valueLabels?.disabled;
      if (!!options.legend.legendTooltip) {
        chart.legend.itemContainers.template.tooltipText = options.legend.legendTooltip.text;
        chart.legend.tooltip.getFillFromObject = options.legend.legendTooltip.getFillFromObject;
        chart.legend.tooltip.background.fill = am4core.color(options.legend.legendTooltip.fill);
      }
      chart.legend.labels.template.text = options.legend.labelsTemplateText;
      if (!!options?.markers) {
        const marker = chart.legend.markers.template.children.getIndex(0);
        marker.strokeWidth = options.markers.strokeWidth;
        marker.strokeOpacity = options.markers.strokeOpacity;
        marker.stroke = am4core.color(options.markers.strokeColor);
        const markerTemplate = chart.legend.markers.template;
        markerTemplate.width = options.markers.width;
        markerTemplate.height = options.markers.height;
      }
    }

    return chart;

  }
}
