import { Component, Input } from '@angular/core';
import { ReportSectionHeaderConfiguration } from 'app-models/report/report-section/report-section-header-configuration.model';

@Component({
  selector: 'app-report-section-header',
  templateUrl: './report-section-header.component.html',
  styleUrls: ['./report-section-header.component.scss']
})
export class ReportSectionHeaderComponent {

  @Input() configuration: ReportSectionHeaderConfiguration;

  constructor() { }

}
