import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KnobConfiguration } from 'app-models/knob/knob.model';
import { getNumberWithFixedDecimalAccuracy } from 'app-scripts/utilities/general';
import { KnobService } from 'app-services/knob.service';
import { TranslationService } from 'app-services/translation.service';
import { isArray } from 'lodash';

@Component({
  selector: 'app-knob',
  templateUrl: './knob.component.html',
  styleUrls: ['./knob.component.scss']
})
export class KnobComponent implements OnChanges {

  @Input() configuration: KnobConfiguration;
  @Input() styleClass: string;
  @Input() data: number;
  color: string;
  tooltip: string;
  rangeColor: string;
  valueColor: string;

  constructor(
    private translationService: TranslationService,
    private knobService: KnobService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes?.configuration?.currentValue) {
      this.assignColors(this.configuration.color);
      this.handleMultipleTooltips();
      this.data = getNumberWithFixedDecimalAccuracy(this.data, this.configuration?.decimalAccuracy);
    }
    if (!!changes.data) {
      const color = this.knobService.getScoreColorByValue(this.data);
      this.assignColors(color);
    }
  }

  private assignColors(color: string): void {
    if (color) {
      this.color = color;
      this.rangeColor = this.knobService.getKnobBackgroundColor(color);
      this.valueColor = color;
    }
  }

  private handleMultipleTooltips = () => {
    this.tooltip = '';
    if (isArray(this.configuration?.tooltip)) {
      this.tooltip = this.configuration?.tooltip?.map((tooltip) => {
        return this.translationService.translate(tooltip.text, tooltip.parameters);
      }).join('\n');
    } else {
      this.tooltip = this.translationService.translate(this.configuration?.tooltip?.text, this.configuration?.tooltip?.parameters);
    }
  }

}
