import { Component } from '@angular/core';

@Component({
  selector: 'app-errors-layout',
  templateUrl: './errors-layout.html',
  styleUrls: ['./errors-layout.scss']
})
export class ErrorsLayoutComponent {

  constructor() {}
}
