import { Component, Input, OnInit } from '@angular/core';
import { TargetSummaryReportData } from 'app-models/target-summary/target-summary-report-data.model';
import { isEmpty } from 'lodash';
import { AssetMetricsService } from './asset-metrics.service';
import { TargetSummaryConfig } from 'app-models/target-summary/target-summary-configuration.model';
import { ChartTypeEnum } from 'app-models/chart/chart-type.enum';
import { ChartService } from 'app-components/chart/chart.service';

@Component({
  selector: 'app-asset-metrics',
  templateUrl: './asset-metrics.component.html',
  styleUrls: ['./asset-metrics.component.scss']
})
export class AssetMetricsComponent implements OnInit {
  @Input() reportData: TargetSummaryReportData;
  configurations: TargetSummaryConfig['assetMetrics'];

  constructor(
    private assetMetricsService: AssetMetricsService,
    private chartService: ChartService,
  ) { }

  ngOnInit(): void {
    if (!isEmpty(this.reportData.topCategories)) {
      this.assetMetricsService.getCategoriesIcons(this.reportData.topCategories);
    }
    this.configurations = this.assetMetricsService.getConfigurations(this.reportData);
    this.initializeTopTechnologiesChart();
  }

  private initializeTopTechnologiesChart = (): void => {
    setTimeout(() => {
      const chartData = this.configurations.technologiesCard.xyChart?.chart?.data;
      const chartId = this.configurations.technologiesCard.xyChart?.chart?.id;
      const chartOptions = this.configurations.technologiesCard.xyChart?.chart?.options;
      if (chartData) {
        this.chartService.initChart(chartData, ChartTypeEnum.Bar, chartId, chartOptions);
      }
    });
  }

}
