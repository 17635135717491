import { Component } from '@angular/core';
import { AssetAnalysisConfiguration } from 'app-models/asset-analysis/asset-analysis-configuration.model';
import { AssetAnalysisReportData } from 'app-models/asset-analysis/asset-analysis-report-data.model';
import { ReportType } from 'app-models/report/report-type.enum';
import { Report } from 'app-models/report/report.model';
import { AssetAnalysisReportService } from './asset-analysis.service';

@Component({
  selector: 'app-asset-analysis',
  templateUrl: './asset-analysis.component.html',
  styleUrls: ['./asset-analysis.component.scss']
})
export class AssetAnalysisReportComponent {

  readonly ReportType = ReportType;
  configurations: AssetAnalysisConfiguration;
  reportData: AssetAnalysisReportData;
  summaryCardsData: Omit<AssetAnalysisReportData, 'appendixes'>;
  appendixesData: AssetAnalysisReportData['appendixes'];

  constructor(
    private assetAnalysisReportService: AssetAnalysisReportService
  ) { }

  onReportData = (report: Report<AssetAnalysisReportData>) => {
    this.reportData = report.data;
    this.summaryCardsData = {
      monitored_assets: this.reportData.monitored_assets,
      vulnerable_assets: this.reportData.vulnerable_assets,
      outdated_technologies: this.reportData.outdated_technologies,
      remediated_assets: this.reportData.remediated_assets
    };
    this.appendixesData = this.reportData.appendixes;
    this.configurations = this.assetAnalysisReportService.getConfigurations(report.request_date, report.metadata.target_name);
  }

}
