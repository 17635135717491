import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserActionType } from 'app-models/actions/user-action-type.enum';
import { CardConfigurations } from 'app-models/card/card-configurations.model';
import { ApplicationDynamicRoutes } from 'app-services/applicationDynamicRoutes.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class BaseCardComponent {
  @Input() card: CardConfigurations;
  @Output() notify: EventEmitter<Notification> = new EventEmitter();
  UserActionType = UserActionType;
  collapsed: boolean;

  constructor(private applicationDynamicRoutes: ApplicationDynamicRoutes, private activateRoute: ActivatedRoute ) { }

  open = () => {
    this.collapsed = false;
  }

  close = () => {
    this.collapsed = true;
  }

  isExpanded = () => {
    return !this.collapsed;
  }

  onClick = () => {
    const link = this.card?.routerLink;
    if (link) {
      this.redirect(link);
    }
  }

  private redirect = (link: string) => {
    const queryParams = undefined;
    this.applicationDynamicRoutes.navigate([link], queryParams, {
      relativeTo: this.activateRoute
    });
  }
}
