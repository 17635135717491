import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class ComponentWithSubscriptions implements OnDestroy {
  subscriptions: Subscription[] = [];

  constructor() {
    this.unsubscribe();
  }

  unsubscribe(): void {
    this.subscriptions?.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
