import { Injectable } from '@angular/core';
import { RequestResult } from 'app-models/common/requestResult.model';
import { Dashboard } from 'app-models/dashboard/dashboard.model';
import { HttpRequestService } from 'app-services/httpRequest.service';
import { UrlService } from 'app-services/url.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportsDataManager {
  readonly CONTROLLER_NAME = 'reports-data';

  constructor(
    private http: HttpRequestService,
    private urlService: UrlService
  ) { }

  getReportData = (reportId: string) => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME, reportId);
    return this.http.get(url).pipe(
      map((res: { data?: Dashboard }) => {
        const requestResult: RequestResult = {
          response: { ...(res?.data || {}) }
        };
        return requestResult;
      })
    );
  }
}
