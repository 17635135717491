import { Injectable } from '@angular/core';
import { AssetFindingsReportData } from 'app-models/asset-findings/asset-findings-report-data.model';
import { FindingsConfiguration } from 'app-models/report/findings-configuration.model';
import { deepCloneObject } from 'app-scripts/utilities/general';
import { AssetFindingsOpeningPageConfiguration } from './configurations/asset-findings-opening-page.configuration';
import { AssetFindingsSectionConfiguration } from './configurations/asset-findings-section.configuration';
import { Text } from 'app-models/common/text.model';
import { FindingsReportSectionBodyItemIdentifier } from 'app-models/report/report-section/findings-report-section-body-item-identifier.enum';
import { TargetAsset } from 'app-models/assets/target-asset.model';
import { AssetDetailsListConfiguration } from './configurations/asset-details-list.configuration';
import { ListItemConfiguration } from 'app-models/list/list-item-configuration.model';
import { AssetCategoryConfig } from 'app-models/assets/asset-category.configuration';
import { TranslationService } from 'app-services/translation.service';
import { ScoreKnobTooltips } from 'app-configurations/asset-score.configuration';
import { TimelineService } from 'app-components/timeline/timeline.service';
import { has, isEmpty } from 'lodash';
import { VectorsSectionConfiguration } from './configurations/vectors-section.configuration';
import { FindingsReportHelper } from 'app-helpers/reports/findings.helper';
import { Indicator } from 'app-models/indicator/indicator.mode';
import { Vector } from 'app-models/vectors/vector.model';

@Injectable({
  providedIn: 'root'
})
export class AssetFindingsReportService {

  constructor(
    private translationService: TranslationService,
    private timelineService: TimelineService,
    private findingsHelper: FindingsReportHelper,
  ){}

  getConfigurations = (report_request_date: number, target_name: string, data: AssetFindingsReportData): FindingsConfiguration => {
    const configurations: FindingsConfiguration = {
      openingPage: deepCloneObject(AssetFindingsOpeningPageConfiguration),
      findings_sections: [],
    };
    configurations.openingPage.date = report_request_date;
    configurations.openingPage.targetName = target_name;
    const sectionConfiguration = deepCloneObject(AssetFindingsSectionConfiguration);
    const asset = data?.asset;
    sectionConfiguration.header.title.text = new Text(asset?.name, false);
    sectionConfiguration.header.icon.class = `${AssetCategoryConfig.icons[asset.category]} ${sectionConfiguration.header.icon.class}`;
    sectionConfiguration.body?.forEach((bodyItem, index) => {
      switch (bodyItem.identifier) {
        case FindingsReportSectionBodyItemIdentifier.Details: {
          sectionConfiguration.body[index].data = this.buildAssetDetailsList(asset);
          break;
        }
        case FindingsReportSectionBodyItemIdentifier.IPAddressesAndPorts: {
          sectionConfiguration.body[index].data = asset.ip_addresses;
          break;
        }
        case FindingsReportSectionBodyItemIdentifier.Enrichments: {
          sectionConfiguration.body[index].data = asset.enrichments;
          break;
        }
        case FindingsReportSectionBodyItemIdentifier.Timeline: {
          sectionConfiguration.body[index].data = this.timelineService.buildCommentsTimeline(asset.timeline);
          break;
        }
        case FindingsReportSectionBodyItemIdentifier.Notes: {
          sectionConfiguration.body[index].data = asset.notes;
          break;
        }
        case FindingsReportSectionBodyItemIdentifier.Remediations: {
          sectionConfiguration.body[index].data = this.findingsHelper.buildAssetRemediationGroups(asset.remediation_groups);
          this.findingsHelper.buildStepsProgressBar(
            asset.remediation_groups,
            sectionConfiguration.body[index].configuration?.progressBarConfiguration);
          break;
        }
        case FindingsReportSectionBodyItemIdentifier.TechnologyStack: {
          sectionConfiguration.body[index].data = asset?.technologies;
        }
      }
    });
    configurations.findings_sections.push(sectionConfiguration);

    const vectors = data?.vectors;
    if (!isEmpty(vectors)) {
      vectors.forEach((vector) => {
        const vectorsSectionConfiguration = deepCloneObject(VectorsSectionConfiguration);
        vectorsSectionConfiguration.header.subtitle.text = new Text('related_asset', true, [asset.name]);
        vectorsSectionConfiguration.header.title.text = new Text(!isEmpty(vector.tags) ? vector.tags[0] : '', false);
        vectorsSectionConfiguration.header.indicators = this.filterIndicators(vectorsSectionConfiguration.header.indicators, vector);
        vectorsSectionConfiguration.body?.forEach((bodyItem, index) => {
          switch (bodyItem.identifier) {
            case FindingsReportSectionBodyItemIdentifier.Details: {
              vectorsSectionConfiguration.body[index].data = this.findingsHelper.buildVectorDetailsList(vector);
              break;
            }
            case FindingsReportSectionBodyItemIdentifier.Steps: {
              vectorsSectionConfiguration.body[index].data = this.findingsHelper.buildVectorSteps(vector);
              break;
            }
          }
        });
        configurations.findings_sections.push(vectorsSectionConfiguration);
      });
    }
    return configurations;
  }

  private buildAssetDetailsList = (asset: TargetAsset): ListItemConfiguration[] => {
    const list = deepCloneObject(AssetDetailsListConfiguration);
    list?.forEach((item) => {
      if (item.path === 'score') {
        const score = asset[item.path];
        item.content = `${score} - ${this.translationService.translate(ScoreKnobTooltips[score].text)}`;
      } else {
        item.content = asset[item.path];
      }
    });
    return list;
  }

  private filterIndicators = (indicators: Indicator[], vector: Vector): Indicator[] => {
    return indicators.filter((indicator) => !has(indicator, 'showIf') || indicator.showIf(vector));
  }
}
