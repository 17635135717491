import { Injectable } from '@angular/core';
import { getEnumKeyByValue, stringFormat } from 'app-scripts/utilities/general';
import { UserLanguage } from 'app-models/user/user.module';
import { UserService } from 'app-services/user.service';
import { LocalizationService } from './localization.service';

@Injectable()
export class TranslationService {
    constructor(
      private userService: UserService,
      private localizationService: LocalizationService
    ) { }

  translate = (value: string, params?: string[]): string | any => {
    let result: string;
    if (typeof value === 'number') {
      value = `${value}`;
    }
    if (value === undefined || value === null || (value.isEmptyOrNullOrUndefined && value.isEmptyOrNullOrUndefined())) { return ''; }
    const translatedValue = this.localizationService.getLanguageValue(value);
    if (translatedValue) {
      result = translatedValue;
    }
    else {
      const baseValue = this.localizationService.getBaseLanguageValue(value);
      if (baseValue) {
        result = baseValue;
      }
      else {
        result = value;
      }
      const language = getEnumKeyByValue(UserLanguage, this.userService.getLanguage());
      console.error(`'${value}' should be translated to: ${language}`);
    }

    const returnValue = params?.length ? stringFormat(result, params) : result;
    return returnValue;
  }
}
