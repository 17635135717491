import { Component, Input, OnChanges } from '@angular/core';
import { KnobConfiguration } from 'app-models/knob/knob.model';
import { TableCellConfiguration } from 'app-models/table/table-cell-configuration.model';
import { TableCellType } from 'app-models/table/table-cell-type.enum';
import { KnobService } from 'app-services/knob.service';

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss']
})
export class TableCellComponent implements OnChanges {

  readonly TableCellType = TableCellType;
  @Input() row: any;
  @Input() config: TableCellConfiguration<any>;
  knobConfiguration: KnobConfiguration;

  constructor(private knobService: KnobService) { }

  ngOnChanges(): void {
    if (this.config.types?.includes(TableCellType.Knob)) {
      this.getKnob();
    }
  }

  getKnob = () => {
    this.knobConfiguration = this.knobService.getKnobConfiguration(this.row[this.config.field], this.config.knobOptions);
  }

}
