import { Component, Input } from '@angular/core';
import { TimelineStep } from 'app-models/timeline/timeline-step.model';
import { TimelineStepType } from 'app-models/timeline/timeline-step-type.enum';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent {

  readonly TimelineStepType = TimelineStepType;
  @Input() steps: TimelineStep[];

  constructor() { }

}
