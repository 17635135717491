import { ChartTypeEnum } from 'app-models/chart/chart-type.enum';
import { SeriesType } from 'app-models/chart/series-type.enum';
import { Text } from 'app-models/common/text.model';
import { TargetSummaryConfig } from 'app-models/target-summary/target-summary-configuration.model';

const DEFAULT_FONT_COLOR = '#7A7577';

export const AssetMetricsCardsConfigurations: TargetSummaryConfig['assetMetrics'] = {
  header: { title: { styleClass: 'color-primary-pink f-s-20 bold', text: new Text('asset_metrics', true) } },
  topCards: [
    {
      name: 'totalAssets',
      emptyStatePlaceholder: new Text('target_summary_report.total_assets.empty_state', true),
      config: {
        header: {
          title: new Text('target_summary_report.total_assets.title', true),
          leftIcon: {
            class: 'far fa-box f-s-18 color-primary-pink p-r-8 f-w-500',
          }
        },
      },
    },
    {
      name: 'discoveredAssets',
      emptyStatePlaceholder: new Text('target_summary_report.discovered_assets.empty_state', true),
      config: {
        header: {
          title: new Text('target_summary_report.discovered_assets.title', true),
          leftIcon: {
            class: 'far fa-lightbulb-on f-s-18 color-primary-pink p-r-8 f-w-500',
          }
        },
      },
    },
    {
      name: 'assignedAssets',
      emptyStatePlaceholder: new Text('target_summary_report.assigned_assets.empty_state', true),
      config: {
        header: {
          title: new Text('target_summary_report.assigned_assets.title', true),
          leftIcon: {
            class: 'far fa-bullseye-pointer f-s-18 color-primary-pink p-r-8 f-w-500',
          }
        },
      },
    },
  ],
  categoriesCard: {
    emptyStatePlaceholder: new Text('target_summary_report.top_categories.empty_state', true),
    config: {
      header: {
        title: new Text('target_summary_report.top_categories.title', true),
        leftIcon: {
          class: 'far fa-boxes f-s-18 color-primary-pink p-r-8 f-w-500',
        }
      },
    },
  },
  technologiesCard: {
    card: {
      emptyStatePlaceholder: new Text('target_summary_report.top_technologies.empty_state', true),
      config: {
        header: {
          title: new Text('target_summary_report.top_technologies.title', true),
          leftIcon: {
            class: 'far fa-archive f-s-18 color-primary-pink p-r-8 f-w-500',
          }
        },
      },
    },
    xyChart: {
      chart: {
        id: 'topTechnologiesChart',
        type: ChartTypeEnum.Bar,
        options: {
          seriesType: SeriesType.Bar,
          legend: {
            useDefaultMarker: true,
            fontSize: 12,
            maxWidth: 2,
            fontColor: DEFAULT_FONT_COLOR,
          },
          markers: {
            width: 12,
            height: 12,
          },
          categoryAxis: {
            category: 'name',
            tooltip: {
              disabled: true,
            },
            label: {
              fontSize: 12,
              wrap: true,
              maxWidth: 200,
              fill: DEFAULT_FONT_COLOR,
            },
            grid: {
              minGridDistance: 1,
              location: 0,
              disabled: true
            },
            cell: {
              cellStartLocation: 0.3,
              cellEndLocation: 0.75,
            }
          },
          valueAxis: {
            tooltip: {
              disabled: true,
            },
            fontColor: DEFAULT_FONT_COLOR,
          },
          serieses: [{
            categoryX: 'name',
            valueY: 'total',
            columns: {
              width: 25,
              cornerRadius: [8, 8, 0, 0],
            },
            labelBullet: {
              text: '{valueY}',
              fontSize: 12,
              truncate: false,
              paddingY: 7,
              fill: DEFAULT_FONT_COLOR,
            },
            fill: '#309EE8',
            colorProperty: 'color',
          },
          {
            categoryX: 'name',
            valueY: 'outdated',
            columns: {
              width: 25,
              cornerRadius: [8, 8, 0, 0],
            },
            labelBullet: {
              text: '{valueY}',
              fontSize: 12,
              truncate: false,
              paddingY: 7,
              fill: DEFAULT_FONT_COLOR,
            },
            fill: '#EB1C62',
            colorProperty: 'color'
          }
          ],
          maskBullets: false,
        },
      },
    },
  }
};
