import { Injectable } from '@angular/core';
import { ApplicationDataStorageService } from 'app-services/applicationDataStorage.service';
import { User, UserLanguage, UserPermissionEnum } from 'app-models/user/user.module';
import { AuthenticationDetails } from 'app-models/user/authentication-details.model';
import { Roles } from 'app-models/user/roles.enum';
import { getArray } from 'app-scripts/utilities/general';
import { intersection, isEmpty, isUndefined } from 'lodash';
import { UserMetadata } from 'app-models/user/user-metadata.model';

@Injectable()
export class UserService {
  constructor(
    private applicationGlobalsService: ApplicationDataStorageService,
  ) { }

  private get _globalUser(): User {
    return this.applicationGlobalsService.user;
  }

  get user(): User {
    const user = this._globalUser;
    if (!user) { return; }

    const { id, email, name } = user;
    return { id, email, name };
  }

  set = (userInfo: AuthenticationDetails['payload']['userObj'] & { language: UserLanguage; }): Promise<void> => {
    return new Promise<void>(async (resolve, reject) => {
      if (!!this._globalUser) { reject(`user already set.`); }
      const user: User = {
        language: userInfo.language,
        name: userInfo.full_name,
        permissons: userInfo.permissions as UserPermissionEnum[],
        role: userInfo.role,
        email: userInfo.email,
        isAdmin: userInfo.is_admin,
        id: `${userInfo.id}`,
        metadata: userInfo.metadata
      };
      this.applicationGlobalsService.user = user;
      resolve();
    });
  }

  hasPermission = (permission: UserPermissionEnum | UserPermissionEnum[]): boolean => {
    if (isUndefined(permission)) { return true; }
    const permissions = getArray(permission);
    return !isEmpty(intersection(permissions, this._globalUser.permissons));
  }

  /**
   * Check if the user has a role. If the user is an admin, function will always return true.
   * @param role Roles to check against. If an array is passed, function will return true if any item is the user's role.
   */
  hasRole = (role: Roles | Roles[]): boolean => {
    const roles = getArray(role);
    const allowed = this.isAdmin() || roles.includes(this._globalUser.role);
    return allowed;
  }

  isAdmin = (): boolean => {
    return !!this._globalUser.isAdmin;
  }

  getMetadata = (): Partial<UserMetadata> => {
    return this._globalUser.metadata || {};
  }

  getLanguage = (): string => {
    const user: User = this._globalUser;
    return user === undefined ? UserLanguage.English : user.language;
  }

}
