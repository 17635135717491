import { Component } from '@angular/core';
import { ReportType } from 'app-models/report/report-type.enum';
import { AssetFindingsReportData } from 'app-models/asset-findings/asset-findings-report-data.model';
import { Report } from 'app-models/report/report.model';
import { AssetFindingsReportService } from './asset-findings.service';
import { FindingsConfiguration } from 'app-models/report/findings-configuration.model';

@Component({
  selector: 'app-asset-findings',
  templateUrl: './asset-findings.component.html',
  styleUrls: ['./asset-findings.component.scss']
})
export class AssetFindingsReportComponent {
  readonly ReportType = ReportType;
  reportData: AssetFindingsReportData;
  configurations: FindingsConfiguration;

  constructor(
    private assetFindingsReportService: AssetFindingsReportService) { }

  onReportData = (report: Report<AssetFindingsReportData>) => {
    this.reportData = report.data;
    this.configurations = this.assetFindingsReportService
      .getConfigurations(report.request_date, report.metadata?.target_name, this.reportData);
  }

}
