import { GenericObject } from 'app-models/common/generic-object';
import { SummaryCard } from 'app-models/summary-card/summary-card.model';
import { Text } from 'app-models/common/text.model';
import { ChartTypeEnum } from 'app-models/chart/chart-type.enum';
import { SeriesType } from 'app-models/chart/series-type.enum';

export const SummaryCardsConfiguration: GenericObject<SummaryCard> = {
  monitored_assets: {
    emptyState: {
      text: new Text('asset_analysis_report.monitored_assets.empty_state', true),
      styleClass: 'f-s-24 bold'
    },
    pieChart: {
      config: {
        coverage: {
          flip: true,
          countColor: '#3a6083',
          totalColor: '#d9d9d9',
        },
        subtitle: {
          text: new Text('asset_analysis_report.monitored_assets.subtitle', true),
          fontSize: '16px',
          color: 'black'
        },
        description: new Text('asset_analysis_report.monitored_assets.details', true),
        descriptionStyleClass: 'monitored-pie-chart-description',
        showAsPercentage: true,
        showCoverageFirst: false,
      },
      chart: {
        id: 'monitoredAssetsPieChart',
        type: ChartTypeEnum.Pie,
        options: {
          seriesType: SeriesType.Pie,
          dataFields: {
            value: 'value',
            category: 'key'
          },
          series: {
            label: {
              disabled: true,
            },
          },
        }
      }
    },
    xyChart: {
      chart: {
        id: 'monitoredAssetsBarChart',
        type: ChartTypeEnum.Bar,
        options: {
          title: 'asset_analysis_report.monitored_assets.bar_chart.title',
          titleMarginBottom: 8,
          seriesType: SeriesType.Bar,
          legend: {
            disabled: true
          },
          categoryAxis: {
            category: 'category',
            tooltip: {
              disabled: true,
            },
            label: {
              fontSize: 8,
              rotation: -90,
              autoRotate: true,
              wrap: true,
              maxWidth: 160
            },
            grid: {
              minGridDistance: 25,
              location: 0
            }
          },
          valueAxis: {
            tooltip: {
              disabled: true,
            },
            fontColor: '#000000',
          },
          series: {
            categoryX: 'category',
            valueY: 'value',
            columns: {
              width: 25,
              cornerRadius: [2, 2, 0, 0],
            },
            strokeWidth: 0,
            fillOpacity: 0.8,
            labelBullet: {
              text: '{valueY}',
              fontSize: 8,
              truncate: false,
              paddingY: 5
            }
          }
        },
      },
    },
  },
  vulnerable_assets: {
    emptyState: {
      text: new Text('asset_analysis_report.vulnerable_assets.empty_state', true),
      styleClass: 'f-s-24 bold'
    },
    pieChart: {
      config: {
        coverage: {
          flip: true,
          countColor: '#e91e63',
          totalColor: '#d9d9d9',
        },
        subtitle: {
          text: new Text('asset_analysis_report.vulnerable_assets.subtitle', true),
          fontSize: '16px',
          color: 'black'
        },
        description: new Text('asset_analysis_report.vulnerable_assets.details', true),
        descriptionStyleClass: 'vulnerable-pie-chart-description',
        showAsPercentage: true,
        showCoverageFirst: false,
      },
      emptyState: {
        text: new Text('asset_analysis_report.vulnerable_assets.pie_chart.placeholder', true),
        styleClass: 'f-s-12 bold'
      },
      chart: {
        id: 'vulnerableAssetsPieChart',
        type: ChartTypeEnum.Pie,
        options: {
          seriesType: SeriesType.Pie,
          dataFields: {
            value: 'value',
            category: 'key'
          },
          series: {
            label: {
              disabled: true,
            },
          },
        }
      }
    },
    xyChart: {
      chart: {
        id: 'vulnerableAssetsBarChart',
        type: ChartTypeEnum.Bar,
        options: {
          title: 'asset_analysis_report.vulnerable_assets.bar_chart.title',
          titleMarginBottom: 8,
          seriesType: SeriesType.Bar,
          legend: {
            disabled: true
          },
          categoryAxis: {
            category: 'category',
            tooltip: {
              disabled: true,
            },
            label: {
              fontSize: 8,
              rotation: -90,
              maxWidth: 160
            },
            grid: {
              minGridDistance: 1,
              location: 0
            }
          },
          valueAxis: {
            tooltip: {
              disabled: true,
            },
            fontColor: '#000000',
          },
          series: {
            categoryX: 'category',
            valueY: 'value',
            columns: {
              width: 8,
              cornerRadius: [2, 2, 0, 0],
            },
            strokeWidth: 0,
            fill: '#e91e63',
            fillOpacity: 0.8,
            labelBullet: {
              text: '{valueY}',
              fontSize: 8,
              truncate: false,
              paddingY: 5
            },
            colorProperty: 'fill'
          }
        },
      },
      noDataPlaceholder: new Text('asset_analysis_report.vulnerable_assets.bar_chart.placeholder', true),
    }
  },
  remediated_assets: {
    emptyState: {
      text: new Text('asset_analysis_report.remediated_assets.empty_state', true),
      styleClass: 'f-s-24 bold'
    },
    pieChart: {
      config: {
        coverage: {
          flip: true,
          countColor: '#0580e3',
          totalColor: '#d9d9d9',
        },
        subtitle: {
          text: new Text('asset_analysis_report.remediated_assets.subtitle', true),
          fontSize: '15px',
          color: 'black'
        },
        description: new Text('asset_analysis_report.remediated_assets.details', true),
        descriptionStyleClass: 'remediated-pie-chart-description',
        showAsPercentage: true,
        showCoverageFirst: false,
      },
      emptyState: {
        text: new Text('asset_analysis_report.appendixes.remediated_assets.empty_state', true),
        styleClass: 'f-s-12 bold'
      },
      chart: {
        id: 'remediatedAssetsPieChart',
        type: ChartTypeEnum.Pie,
        options: {
          seriesType: SeriesType.Pie,
          dataFields: {
            value: 'value',
            category: 'key'
          },
          series: {
            label: {
              disabled: true,
            },
          },
        }
      }
    },
    xyChart: {
      chart: {
        id: 'remediatedAssetsBarChart',
        type: ChartTypeEnum.Bar,
        options: {
          title: 'asset_analysis_report.remediated_assets.bar_chart.title',
          titleMarginBottom: 8,
          seriesType: SeriesType.Bar,
          legend: {
            disabled: true
          },
          categoryAxis: {
            category: 'category',
            tooltip: {
              disabled: true,
            },
            label: {
              fontSize: 8,
              rotation: -90,
              maxWidth: 160
            },
            grid: {
              minGridDistance: 1,
              location: 0
            }
          },
          valueAxis: {
            tooltip: {
              disabled: true,
            },
            fontColor: '#000000',
          },
          series: {
            categoryX: 'category',
            valueY: 'value',
            columns: {
              width: 8,
              cornerRadius: [2, 2, 0, 0],
            },
            strokeWidth: 0,
            fill: '#0580e3',
            fillOpacity: 0.8,
            labelBullet: {
              text: '{valueY}',
              fontSize: 8,
              truncate: false,
              paddingY: 5
            },
            colorProperty: 'fill'
          }
        },
      },
      noDataPlaceholder: new Text('asset_analysis_report.remediated_assets.bar_chart.placeholder', true),
    }
  },
  outdated_technologies: {
    emptyState: {
      text: new Text('asset_analysis_report.outdated_technologies.empty_state', true),
      styleClass: 'f-s-24 bold'
    },
    pieChart: {
      config: {
        coverage: {
          flip: true,
          countColor: '#fe0061',
          totalColor: '#d9d9d9',
        },
        subtitle: {
          text: new Text('asset_analysis_report.outdated_technologies.subtitle', true),
          fontSize: '16px',
          color: 'black'
        },
        description: new Text('asset_analysis_report.outdated_technologies.details', true),
        descriptionStyleClass: 'outdated-technologies-pie-chart-description',
        showAsPercentage: true,
        showCoverageFirst: false,
      },
      chart: {
        id: 'outdatedTechnologiesPieChart',
        type: ChartTypeEnum.Pie,
        options: {
          seriesType: SeriesType.Pie,
          dataFields: {
            value: 'value',
            category: 'key'
          },
          series: {
            label: {
              disabled: true,
            },
          },
        }
      },
      emptyState: {
        text: new Text('asset_analysis_report.outdated_technologies.empty_state', true),
        styleClass: 'f-s-12 bold'
      },
    },
    xyChart: {
      chart: {
        id: 'outdatedTechnologiesBarChart',
        type: ChartTypeEnum.Bar,
        options: {
          title: 'asset_analysis_report.outdated_technologies.bar_chart.title',
          titleMarginBottom: 8,
          seriesType: SeriesType.Bar,
          legend: {
            disabled: true
          },
          categoryAxis: {
            category: 'category',
            tooltip: {
              disabled: true,
            },
            label: {
              fontSize: 8,
              rotation: -90,
              autoRotate: true,
              maxWidth: 180,
              wrap: true
            },
            grid: {
              minGridDistance: 2,
              location: 0
            }
          },
          valueAxis: {
            tooltip: {
              disabled: true,
            },
            fontColor: '#000000',
          },
          series: {
            categoryX: 'category',
            valueY: 'value',
            columns: {
              width: 10,
              cornerRadius: [2, 2, 0, 0],
            },
            strokeWidth: 0,
            fillOpacity: 0.8,
            labelBullet: {
              text: '{valueY}',
              fontSize: 8,
              truncate: false,
              paddingY: 5
            }
          },
        },
      },
      noDataPlaceholder: new Text('asset_analysis_report.outdated_technologies.bar_chart.placeholder', true)
    }
  }
};
