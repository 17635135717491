import { AssetCategory } from 'app-models/assets/asset-category.enum';

export const AssetCategoryConfig = {
  icons: {
    [AssetCategory.AdminAndSensitiveInformation]: 'far fa-user-cog',
    [AssetCategory.Authentication]: 'far fa-key',
    [AssetCategory.CloudAssets]: 'far fa-cloud',
    [AssetCategory.Databases]: 'far fa-database',
    [AssetCategory.DevelopmentEnvironments]: 'far fa-code',
    [AssetCategory.EmailAndExchange]: 'far fa-envelope',
    [AssetCategory.FTPsAndFileManagements]: 'far fa-folder-tree',
    [AssetCategory.IPAddresses]: 'far fa-globe',
    [AssetCategory.IoT]: 'fal fa-router',
    [AssetCategory.PublicAndDMZ]: 'far fa-globe-asia',
    [AssetCategory.SecuritySolutions]: 'far fa-shield',
    [AssetCategory.SSH]: 'far fa-terminal',
    [AssetCategory.Storage]: 'fal fa-hdd',
    [AssetCategory.Support]: 'fas fa-user-headset',
    [AssetCategory.TaskManagementPlatforms]: 'far fa-columns',
    [AssetCategory.VPNsAndProxies]: 'far fa-shield',
    [AssetCategory.CRMs]: 'far fa-comment',
    [AssetCategory.APIs]: 'far fa-link',
    [AssetCategory.VoIP]: 'far fa-phone-square',
    [AssetCategory.Cam]: 'far fa-cctv',
    [AssetCategory.Others]: 'far fa-ellipsis-h-alt',
  },
  order: {
    [AssetCategory.AdminAndSensitiveInformation]: 0,
    [AssetCategory.Authentication]: 1,
    [AssetCategory.CloudAssets]: 2,
    [AssetCategory.Databases]: 3,
    [AssetCategory.DevelopmentEnvironments]: 4,
    [AssetCategory.EmailAndExchange]: 5,
    [AssetCategory.FTPsAndFileManagements]: 6,
    [AssetCategory.IPAddresses]: 7,
    [AssetCategory.IoT]: 8,
    [AssetCategory.PublicAndDMZ]: 9,
    [AssetCategory.SecuritySolutions]: 10,
    [AssetCategory.SSH]: 11,
    [AssetCategory.Storage]: 12,
    [AssetCategory.Support]: 13,
    [AssetCategory.TaskManagementPlatforms]: 14,
    [AssetCategory.VPNsAndProxies]: 15,
    [AssetCategory.CRMs]: 16,
    [AssetCategory.APIs]: 17,
    [AssetCategory.VoIP]: 18,
    [AssetCategory.Cam]: 19,
    [AssetCategory.Others]: 20,
  }

};
