import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PieChart } from 'app-models/chart/pie-chart.model';
import { Text } from 'app-models/common/text.model';
import * as am4core from '@amcharts/amcharts4/core';
import { isUndefined } from 'lodash';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnChanges {
  @Input() pieChart: PieChart;
  @Output() notify: EventEmitter<Notification> = new EventEmitter<Notification>();

  constructor() { }

  ngOnChanges(): void {
    this.buildPieChartCard();
  }

  buildPieChartCard = () => {
    const total = this.pieChart?.config?.coverage?.total;
    const totalColor = this.pieChart?.config?.coverage?.totalColor;
    const count = this.pieChart?.config?.coverage?.count;
    const countColor = this.pieChart?.config?.coverage?.countColor;
    const flip = this.pieChart?.config?.coverage?.flip;
    if (!!total || !!count) {
      if (isUndefined(this.pieChart.config.description)) {
        this.pieChart.config.description = new Text('coverage_precent', true, [
          ((count / total) * 100).toFixed(2)
        ]);
      }
      this.pieChart.chart.data = this.buildPieChartData(total - count, count, countColor, totalColor, flip);
      this.notify.emit({
        type: NotificationTypeEnum.Ready,
        data: this.pieChart.chart.type,
      });
    }
  }

  private buildPieChartData = (total: number, count: number, counterColor?: string, totalColor?: string, flip?: boolean) => {
    const dataArray = [{
      key: 'count',
      value: count,
      color: am4core.color(counterColor || '#607D8B'),
    }, {
      key: 'total',
      value: total,
      color: am4core.color(totalColor || '#303030'),
    }];
    if (!!flip) {
      dataArray.reverse();
    }
    return dataArray;
  }
}
