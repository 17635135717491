import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { UrlParameters } from 'app-models/report/url-parameters.enum';
import { ReportsDataService } from 'app-services/reports-data.service';
import { UrlService } from 'app-services/url.service';
import { Report } from 'app-models/report/report.model';

@Component({
  selector: 'app-base-report',
  templateUrl: './base-report.component.html',
  styleUrls: ['./base-report.component.scss']
})
export class BaseReportComponent<T = any> extends ComponentWithSubscriptions implements OnInit {

  private reportId: string;
  @Output() reportData: EventEmitter<Report<T>> = new EventEmitter<Report<T>>();

  constructor(
    protected reportsDataService: ReportsDataService,
    protected urlService: UrlService) {
      super();
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize(): void {
    this.reportId = this.urlService.getUrlParam(UrlParameters.ReportId);
    this.subscriptions.push(
      this.reportsDataService.getReportsData(this.reportId)
        .subscribe((res) => {
          this.reportData.emit(res.response);
        })
    );
  }

}
