import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import * as am4core from '@amcharts/amcharts4/core';
import { isUndefined } from 'lodash';

export class ForceDirectedTreeHelperV2 {

  initChart = (id: string, options?: any): am4plugins_forceDirected.ForceDirectedTree => {
    const chart = this.createChart(id, options);
    return chart;
  }

  private createChart = (id: string, options?: any): am4plugins_forceDirected.ForceDirectedTree => {
    const chart = am4core.create(id, am4plugins_forceDirected.ForceDirectedTree);
    chart.logo.__disabled = true;
    chart.zoomable = options.zoomable;
    const series = new am4plugins_forceDirected.ForceDirectedSeries();
    series.manyBodyStrength = options.series.manyBodyStrength;
    series.centerStrength = options.series.centerStrength;
    series.linkWithStrength = options.series.linkAttractionStrength;
    series.minRadius = options.series.minRadius;
    series.maxRadius = options.series.maxRadius;
    series.dataFields = {
      color: options.series.dataFields.color,
      id: options.series.dataFields.id,
      value: options.series.dataFields.value,
      name: options.series.dataFields.name,
      category: options.series.dataFields.category,
      children: options.series.dataFields.children,
      collapsed: options.series.dataFields.collapsed,
      linkWith: options.series.dataFields.linkWith,
      fixed: options.series.dataFields.fixed,
    };

    series.links.template.propertyFields.strength = options.series.links.propertyFields.strength;
    series.links.template.propertyFields.distance = options.series.links.propertyFields.distance;
    series.links.template.propertyFields.strokeWidth = options.series.links.propertyFields.strokeWidth;
    series.links.template.propertyFields.strokeOpacity = options.series.links.propertyFields.strokeOpacity;
    series.links.template.strokeWidth = options.series.links.strokeWidth;
    series.links.template.strokeOpacity = options.series.links.strokeOpacity;
    series.links.template.strength = options.series.links.strength;

    series.nodes.template.label.propertyFields.html = options.series.nodes.label.propertyFields.html;
    series.nodes.template.label.propertyFields.align = 'center';
    series.nodes.template.label.propertyFields.contentAlign = 'center';
    series.nodes.template.label.propertyFields.contentValign = 'middle';
    series.nodes.template.label.fill = this.createColor(options.text_color);
    series.nodes.template.outerCircle.propertyFields.fill = options.series.nodes.outerCircle.propertyFields.outerCircleColor;
    series.nodes.template.outerCircle.propertyFields.fillOpacity = options.series.nodes.outerCircle.propertyFields.fillOpacity;
    series.nodes.template.outerCircle.filters.push(new am4core.DropShadowFilter());
    series.nodes.template.tooltipText = options.series.nodes.tooltipText;
    series.tooltip.getFillFromObject = options.series.nodes.tooltip.tooltipFillFromObject;
    series.nodes.template.togglable = options.series.nodes.toggable;
    series.nodes.template.propertyFields.x = options.series.nodes.propertyFields.x;
    series.nodes.template.propertyFields.y = options.series.nodes.propertyFields.y;

    series.nodes.template.events.on('hit', (event): void => {
      const nodeDataContext: any = event.target.dataItem.dataContext;
      nodeDataContext.clicked = true;
      if (options?.nodeClickHandler) {
        options?.nodeClickHandler(event);
      }
      if (nodeDataContext.clickHandler) {
        nodeDataContext.clickHandler(event);
      }
    }, this);

    series.nodes.template.adapter.add('tooltipText', (text, target) => {
      if (target.dataItem) {
        const dataContext: any = target.dataItem.dataContext;
        if (isUndefined(dataContext.tooltip)) {
          return '';
        }
      }
      return text;
    });

    chart.series.push(series);

    return chart;
  }

  createColor = (color: string): am4core.Color => {
    return am4core.color(color);
  }


}
