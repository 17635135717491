
import { HttpHeaders } from '@angular/common/http';
import { AssetScore } from 'app-models/common/asset-score.enum';
import { Priority } from 'app-models/opportunity/priority.enum';
import { FileTypeEnum } from 'app-models/file/type.enum';
import * as _ from 'lodash';
import { every, isEmpty, uniqBy } from 'lodash';

// check if object has values inside
// tslint:disable:typedef
export function ObjectHasProperties(obj: any) {
  if (obj === undefined) {
    obj = {};
  }
  return Object.getOwnPropertyNames(obj).length > 0;
}

export function getHttpRequestOptions(method?: string) {
  const contentType = method?.toLowerCase() === 'search' ? 'application/json' : 'text/plain';
  const headers = new HttpHeaders();
  headers
    .set('Content-Type', contentType)
    .set('json', 'true');
  return ({
    headers,
    withCredentials: true
  });
}

export function getFileMimeType(type: FileTypeEnum) {
  switch (type) {
    case FileTypeEnum.TXT:
      return 'text/plain';
    case FileTypeEnum.JSON:
      return 'text/json';
    case FileTypeEnum.CSV:
      return 'text/csv';
    case FileTypeEnum.PDF:
      return 'application/pdf';
    default:
      return 'application/octet-stream';
  }
}

export function getEnumKeyByValue<T>(myEnum: T, value: any): string {
  let result: string;
  const keys = Object.keys(myEnum);
  // tslint:disable-next-line: prefer-for-of
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    if (myEnum[key] === value) {
      result = key;
      break;
    }
  }
  return result;
}

export class Guid {
  constructor(public guid: string) {
    this._guid = guid;
  }

  private _guid: string;

  // Static member
  static create(): Guid {
    let result: string;
    let i: string;
    let j: number;

    result = '';
    for (j = 0; j < 32; j++) {
      if (j === 8 || j === 12 || j === 16 || j === 20) {
        result = result + '-';
      }
      i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
      result = result + i;
    }
    return new Guid(result);
  }

  public ToString(): string {
    return this.guid;
  }
}

export function getSubStringByStardAndEndIndex(year: string, startIndx: number, endIndx: number): string {
  return year.slice(startIndx, endIndx);
}

export function copyToClipboard(value: any): any {
  document.addEventListener('copy', (e: ClipboardEvent) => {
    const ctrlPusKeyC = window.getSelection().toString();
    value = ctrlPusKeyC === '' ? value : ctrlPusKeyC;
    e.clipboardData.setData('text/plain', (value));
    e.preventDefault();
    document.removeEventListener('copy', null);
  });
  document.execCommand('copy');
}

export function htmlToText(value: string): string {
  return value.replace(/<\/?[^>]+(>|$)/g, '');
}

export function deepCloneObject<T = any>(object: T): T {
  return _.cloneDeep(object);
}

export function getUrlParams(url: string): any {
  if (url === undefined) { return {}; }
  url = url.split('+').join(' ');
  let tokens: string[] | RegExpExecArray;
  // tslint:disable-next-line: one-variable-per-declaration
  const params = {}, re = /[?&]?([^=]+)=([^&]*)/g;
  // tslint:disable-next-line: no-conditional-assignment
  while (tokens = re.exec(url)) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }
  return params;
}

/**
 * @example alert(String.Format("Hello {0}. Yes, hello {0} again. My name is {1}", "world", "Miron"));
 * @param format
 * @param args
 */
export function stringFormat(format: string, args: string[]) {
  let result = format;
  for (let i = 1; i <= args.length; i++) {
    if (Array.isArray(args[i - 1])) {
      let replacement = '';
      const arg = args[i - 1];
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < arg.length; index++) {
        const element = arg[index];
        replacement += '"' + element + '",';
      }
      replacement = replacement.slice(0, -1);
      result = result.replace(new RegExp('\\{' + (i - 1) + '\\}', 'g'), replacement);
    } else if (typeof (args[i - 1]) !== 'object') {
      const arg = `${args[i - 1]}`;
      result = result.replace(new RegExp('\\{' + (i - 1) + '\\}', 'g'), arg);
    }
  }
  return result;
}

export function getPercentInPixels(percent: number, fullSize: number) {
  return (percent / 100) * fullSize;
}

/**
 * Get the diff between tow arrays
 * @param list
 * @param originalList
 */
export function getArrayDiff(list: string[], originalList: string[]): any {
  const added = list.filter((x: any) => !originalList.includes(x));
  const removed = originalList.filter((x: any) => !list.includes(x));

  return {
    removed, added
  };
}

export function getObjArrayDiff(list: string[], originalList: any[], field: string): any {
  const added = list.filter(comparer(originalList, field));
  const removed = originalList.filter(comparer(list, field));

  return {
    removed, added
  };
}

function comparer(otherArray: any, field: any): any {
  // tslint:disable-next-line: only-arrow-functions
  return function (current: { [x: string]: any; }) {
    // tslint:disable-next-line: only-arrow-functions
    return otherArray.filter(function (other: { [x: string]: any; }) {
      return other[field] === current[field];
    }).length === 0;
  };
}

export function getOportunityNumber(severity: string): number {
  switch (severity) {
    case 'low':
      return 1;
    case 'medium':
      return 2;
    case 'high':
      return 3;
    case 'critical':
      return 4;
  }
}

export function openUrlInNewTab(url: string) {
  if (!(url.contains('://') && url.indexOf('://') < url.indexOf('.'))) {
    url = `http://${url}`;
  }
  const win = window.open(url, '_blank');
  win.focus();
}

export function findWordIndex(str: string, searchingFor: string) {
  const needle = searchingFor;
  const re = new RegExp(needle, 'gi');
  const haystack = str;

  const results = [];
  while (re.exec(haystack)) {
    results.push({ index: re.lastIndex - searchingFor.length, length: searchingFor.length });
  }
  return results;
}

export function parseHtml(line: string) {
  const el = document.createElement('html');
  el.innerHTML = line;
  return el.innerText;
}

export function getEscapedRows(text: string) {
  const rows = text.split('\n');
  return rows.map(row => {
    return parseHtml(row);
  });
}

export function getEscapedText(text: string) {
  const rows = text.split('\n');
  const r = rows.map(row => {
    return parseHtml(row);
  });
  return r.join('\n');
}

export function getAcronyms(value: string) {
  value = value.trim();
  const splitedValue = value.split(' ');
  return `${splitedValue[0].getFirstLetter().toUpperCase()}${splitedValue[splitedValue.length - 1].getFirstLetter().toUpperCase()}`;
}

export function bigNumbersSuffix(value: number): string {
  if (value <= 999) {
    return value.toString();
  }
  else if (value >= 1000 && value <= 999999) {
    return ((value / 100) % 10 === 0 ? (value / 1000) : (value / 1000).toFixed(1)) + 'K';
  }
  else if (value >= 1000000 && value <= 999999999) {
    return ((value / 100000) % 10 === 0 ? (value / 1000000) : (value / 1000000).toFixed(1)) + 'M';
  }
  else if (value >= 1000000000 && value <= 999999999999) {
    return ((value / 100000000) % 10 === 0 ? (value / 1000000000) : (value / 1000000000).toFixed(1)) + 'B';
  }
  else {
    return value.toString();
  }
}

export function getSelectedValue(value: any): string {
  let result = 'selected';
  value = Boolean(value);
  if (!value) {
    result = 'not_selected';
  }
  return result;
}

export function addEscapeSequenceInCsvField(ValueToEscape: string) {
  return '\"' + ValueToEscape + '\"';
}

export function countTextAreaLines(textarea: any) {
  let _buffer;
  if (_buffer == null) {
    _buffer = document.createElement('textarea');
    _buffer.style.border = 'none';
    _buffer.style.height = '0';
    _buffer.style.overflow = 'hidden';
    _buffer.style.padding = '0';
    _buffer.style.position = 'absolute';
    _buffer.style.left = '0';
    _buffer.style.top = '0';
    _buffer.style.zIndex = '-1';
    document.body.appendChild(_buffer);
  }

  const cs = window.getComputedStyle(textarea);
  // tslint:disable-next-line:radix
  const pl = parseInt(cs.paddingLeft);
  // tslint:disable-next-line:radix
  const pr = parseInt(cs.paddingRight);
  // tslint:disable-next-line:radix
  let lh = parseInt(cs.lineHeight);

  // [cs.lineHeight] may return 'normal', which means line height = font size.
  // tslint:disable-next-line:radix
  if (isNaN(lh)) { lh = parseInt(cs.fontSize); }

  // Copy content width.
  _buffer.style.width = (textarea.clientWidth - pl - pr) + 'px';

  // Copy text properties.
  _buffer.style.font = cs.font;
  _buffer.style.letterSpacing = cs.letterSpacing;
  _buffer.style.whiteSpace = cs.whiteSpace;
  _buffer.style.wordBreak = cs.wordBreak;
  _buffer.style.wordSpacing = cs.wordSpacing;
  _buffer.style.wordWrap = cs.wordWrap;

  // Copy value.
  _buffer.value = textarea.value;

  let result = Math.floor(_buffer.scrollHeight / lh);
  if (result === 0) { result = 1; }

  document.body.removeChild(_buffer);
  return result;
}

export function unEscapeHtmlCharacters(value: string): string {
  return _.unescape(value);
}


export function checkValue(value: any) {
  switch ((typeof value).toString()) {
    case 'object':
      return !_.isEmpty(value) ? value : undefined;
    default:
      return value;
  }
}

export function countDivLines(div: any) {
  const cs = window.getComputedStyle(div);
  // tslint:disable-next-line:radix
  let lh = parseInt(cs.lineHeight);

  // tslint:disable-next-line:radix
  if (isNaN(lh)) { lh = parseInt(cs.fontSize); }

  let result = Math.floor(div.clientHeight / lh);
  if (result === 0) { result = 1; }

  return result;
}


export function getTextAreaPixelHeight(textarea: any) {
  let _buffer;
  if (_buffer == null) {
    _buffer = document.createElement('textarea');
    _buffer.style.border = 'none';
    _buffer.style.height = '0';
    _buffer.style.overflow = 'hidden';
    _buffer.style.padding = '0';
    _buffer.style.position = 'absolute';
    _buffer.style.left = '0';
    _buffer.style.top = '0';
    _buffer.style.zIndex = '-1';
    document.body.appendChild(_buffer);
  }

  const cs = window.getComputedStyle(textarea);
  // tslint:disable-next-line:radix
  const pl = parseInt(cs.paddingLeft);
  // tslint:disable-next-line:radix
  const pr = parseInt(cs.paddingRight);


  // Copy content width.
  _buffer.style.width = (textarea.clientWidth - pl - pr) + 'px';

  // Copy text properties.
  _buffer.style.font = cs.font;
  _buffer.style.letterSpacing = cs.letterSpacing;
  _buffer.style.whiteSpace = cs.whiteSpace;
  _buffer.style.wordBreak = cs.wordBreak;
  _buffer.style.wordSpacing = cs.wordSpacing;
  _buffer.style.wordWrap = cs.wordWrap;

  // Copy value.
  _buffer.value = textarea.value;
  const result = _buffer.scrollHeight;
  document.body.removeChild(_buffer);
  return result;
}

// Returns a 1 level deep indication if object is empty. Works on objects with values which are strings, objects, or arrays.
export function isObjectEmpty(object: object): boolean {
  const empty = _.isEmpty(object) || Object.values(object).every(value => _.isEmpty(value));

  return empty;
}

// sleep function resolves a promise after set amount of milliseconds
export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function isEqualNormalizedStrings(value1: string, value2: string): boolean {
  return value1?.toLowerCase().normalizeSpaces() === value2?.toLowerCase().normalizeSpaces();
}

export function getValueOrEmptyString(attribute: string, obj?: any) {
  return (obj && obj[attribute]) || '';
}

export function normalizeUnixTimestamp(val: any) {
  if (val.toString().length < 12) {
    return val * 1000;
  }

  return val;
}

export function clearDuplicatesFromObjectArray(arr: any[], uniqProperty: string) {
  return uniqBy(arr, uniqProperty);
}

export function upperCaseFirstLetter(str: string) {
  if (!str) {
    return '';
  }
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getArray<T>(element: T | T[]): T[] {
  if (Array.isArray(element)) {
    return element;
  }

  return [element];
}

export function getSelectedItems<T extends { selected?: boolean; }>(list: T[]): T[] {
  return list?.filter((item) => !!item.selected) || [];
}

export function isAllSelected<T extends { selected?: boolean; }>(list: T[]): boolean {
  return !isEmpty(list) && every(list, (item) => !!item.selected);
}

export function chunkArrayInGroups(arr: any[], size: number) {
  if (_.isEmpty(arr)) {
      return [];
  }
  const myArray = [];
  for (let i = 0; i < arr.length; i += size) {
      myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

export function getEnumKeysArray(enumObject: unknown, valueType: unknown): unknown[] {
  return Object.keys(enumObject).filter(k => typeof enumObject[k as any] === valueType);
}

export function getEnumValuesArray(enumObject: unknown, valueType: unknown): unknown[] {
  return getEnumKeysArray(enumObject, valueType).map(k => enumObject[k as any]);
}

export function getLowestAssetScore(): AssetScore {
  return Math.min(...Object.values(AssetScore).filter(x => Number.isInteger(x)) as number[]);
}

export function getNumberWithFixedDecimalAccuracy(num: number, precision: number) {
  return Number.parseFloat(num.toFixed(precision || 0));
}

export function getPrioritiesArray(): Priority[] {
  return Object.values(Priority).filter(x => Number.isInteger(x)) as number[];
}

export function getPercentage(numerator: number, denominator: number): number {
  return (numerator / denominator) * 100;
}
