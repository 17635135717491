import { Routes } from '@angular/router';
import { MainLayoutComponent } from 'app-layouts/main/main.layout';
import { ReportType } from 'app-models/report/report-type.enum';
import { TargetDashboardReportComponent } from 'app-reports/target-dashboard/target-dashboard.report';
import { AssetAnalysisReportComponent } from 'app-reports/asset-analysis/asset-analysis.component';
import { VectorFindingsReportComponent } from 'app-reports/vector-findings/vector-findings.component';
import { AssetFindingsReportComponent } from 'app-reports/asset-findings/asset-findings.component';
import { TargetSummaryReportComponent } from 'app-reports/target-summary/target-summary.component';

export const DynamicRoutes: Routes = [
  {
    path: '', redirectTo: '/', pathMatch: 'full'
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: `${ReportType.TargetDashboard}`, component: TargetDashboardReportComponent },
      { path: `${ReportType.AssetAnalysis}`, component: AssetAnalysisReportComponent },
      { path: `${ReportType.VectorFindings}`, component: VectorFindingsReportComponent },
      { path: `${ReportType.AssetFindings}`, component: AssetFindingsReportComponent },
      { path: `${ReportType.TargetSummary}`, component: TargetSummaryReportComponent },
    ]
  }
];
