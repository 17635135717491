import { Injectable } from '@angular/core';
import { ChartTypeEnum } from 'app-models/chart/chart-type.enum';
import * as am4core from '@amcharts/amcharts4/core';
import { ChartsHelperByTypeMap } from 'app-factories/charts.factory';
import { ChartOptions } from 'app-models/chart/chart-option.model';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  constructor() { }

  initChart = (data: any, type: ChartTypeEnum, id: string, options?: ChartOptions): any => {
    const domElement = document.getElementById(id);
    if (!!domElement) {
      const helper: any = ChartsHelperByTypeMap.get(type);
      return helper.initChart(data, id, options);
    }
  }

  disposeAll = () => {
    am4core.disposeAllCharts();
  }

  disposeChart = (chart?: any) => {
    chart?.dispose();
  }
}
