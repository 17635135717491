import { Injectable } from '@angular/core';
import { ApplicationDataStorageService } from 'app-services/applicationDataStorage.service';
import { Location } from '@angular/common';


@Injectable()
export class UrlService {
    constructor(
        private applicationGlobalsService: ApplicationDataStorageService,
        private location: Location
    ) { }

    getCurrentPath(): string {
        return this.location.path();
    }

    getCurrentPage(): any {
        const path = this.location.path().replace('/', '');
        return path.split('?')[0].split('/')[0];
    }

    getRequestUrl(controllerName: string, methodName?: string, addSearch?: boolean): any {
        let baseApi = this.applicationGlobalsService.globalConfigurations.apiUrl;
        if (addSearch) {
            baseApi += 'search/';
        }
        let result = `${baseApi}${controllerName}`;
        if (methodName) {
            result += `/${methodName}`;
        }

        return result;
    }

    getQueryParams(): any[] {
      const retunParams: any[] = [];
      const params = this.getParams();
      params?.forEach((param: any) => {
            retunParams.push(param);
        });
      return retunParams;
    }


    getUrlParam(name: string): any {
        const params = this.getQueryParams();
        const result = {};
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < params.length; index++) {
            const param = params[index];
            result[param[0]] = param[1];
        }
        return result[name];
    }

    getMainUrl = () => {
        return this.applicationGlobalsService.globalConfigurations.mainUrl;
    }

    private getParams(): string[][] {
      const urlToMap = window.document.URL.toString().split('?')[1] || this.getDecodedUrl().split('?')[1];
      return this.mapQueryParams(urlToMap);
    }

    private mapQueryParams(url: string): string[][] {
      return url?.replace(/%2C/g, ',')?.split('&')?.map(v => v.split('=')) || [];
    }

    private getDecodedUrl(encodedUrl?: string): string {
      const decodedUrl = decodeURIComponent(encodedUrl || window.document.URL.toString());
      if (decodedUrl.includes('%') && !decodedUrl.includes('?') && !decodedUrl.includes('=')) {
        return this.getDecodedUrl(decodedUrl);
      }
      return decodedUrl;
    }
}
