import { Injectable } from '@angular/core';
import { User } from 'app-models/user/user.module';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import * as globalConfiguration from 'app-environments/environment';

@Injectable()
export class ApplicationDataStorageService {

  static instance: ApplicationDataStorageService;
  // tslint:disable:variable-name
  private _globalConfigurations: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private _user: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
  private _localization: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private _baseLocalization: BehaviorSubject<any> = new BehaviorSubject<any>({}); // base language is english
  private _appName: BehaviorSubject<string> = new BehaviorSubject(undefined);
  constructor() {
    return ApplicationDataStorageService.instance = ApplicationDataStorageService.instance || this;
  }

  setGlobalConfigurations = () => {
    this._globalConfigurations.next(globalConfiguration.environment);
  }

  get globalConfigurations(): any {
    return this._globalConfigurations.value;
  }

  /**
   * User should be set only only from UserService!
   */
  set user(user: User) {
    this._user.next(user);
  }

  /**
   * User should be accessed only from UserService!
   */
  get user(): User {
    return this._user.value;
  }

  set localization(localizaionFile: any) {
    this._localization.next(localizaionFile);
  }

  get localization(): any {
    return this._localization.value;
  }

  set baseLocalization(localizaionFile: any) {
    this._baseLocalization.next(localizaionFile);
  }

  get baseLocalization(): any {
    return this._baseLocalization.value;
  }

  set appName(appName: string) {
    this._appName.next(appName);
  }

  get appName(): string {
    return this._appName.value;
  }
}

