/** Order of discovered assets cards is based on the order of the enum!
 * Add members in order
*/
export enum AssetCategory {
  AdminAndSensitiveInformation = 'admin_and_sensitive_information',
  Authentication = 'authentication',
  CloudAssets = 'cloud_assets',
  Databases = 'databases',
  DevelopmentEnvironments = 'development_environments',
  EmailAndExchange = 'email_and_exchange',
  FTPsAndFileManagements = 'ftps_and_file_management',
  IPAddresses = 'ip_addresses',
  IoT = 'iot',
  PublicAndDMZ = 'public_and_dmz',
  SecuritySolutions = 'security_solutions',
  SSH = 'ssh',
  Storage = 'storage',
  Support = 'support',
  TaskManagementPlatforms = 'task_management_platforms',
  VPNsAndProxies = 'vpns_and_proxies',
  CRMs = 'crms',
  APIs = 'apis',
  VoIP = 'voip',
  Cam = 'cam',
  Others = 'others',
}
