export enum NotificationTypeEnum {
  ClearSearch = 'clear_search',
  RemoveSelection = 'remove_selection',
  SortChanged = 'sort_changed',
  Edit = 'edit',
  Cancel = 'cancel',
  ValueChanged = 'value_changed',
  ItemsLoaded = 'items_loaded',
  GetMoreData = 'get_more_data',
  Delete = 'delete',
  ItemSelected = 'item_selected',
  Open = 'open',
  Excute_Search = 'excute_search',
  Select_One = 'select_one',
  Item_Changed = 'item_changed',
  CreateItem = 'create_item',
  ItemClicked = 'item_clicked',
  Terminate = 'terminate',
  Ready = 'ready',
  Close = 'close',
  Save = 'save',
  AutocompleteSearch = 'autocomplete_search',
  Download = 'download',
  FileChanged = 'file_changed',
  ErrorOccured = 'error_occured',
  Refresh = 'refresh',
  DrawMode = 'DrawMode',
  ChangedBounds = 'ChangedBounds',
  Execute = 'execute',
  SaveEnrichment = 'save_enrichment',
  RemoveItem = 'remove_item',
  RemoveAll = 'remove_all',
  OpenResourceEnrichment = 'resource',
  OpenEntityEnrichment = 'entity',
  Add = 'add',
  FilterChanged = 'filter_changed',
  BulkActionClicked = 'bulk_action_clicked',
  OpenDiscoveryEnrichment = 'discovery',
  ButtonSelected = 'button_selected',
  CloseDialog = 'close_dialog',
  OpenDialog = 'open_dialog',
  ClearAllFilters = 'clear_all_filters',
  Done = 'done',
  Undone = 'undone',
  EditItem = 'edit_item',
  Report = 'report',
  CheckBoxClicked = 'check_box_clicked',
  SelectMany = 'select_many',
  DragEventEnd = 'drag_event_end',
  DragEventStart = 'drag_event_start',
  LogOut = 'log_out',
  DropEvent = 'drop_event',
  DownloadCompleted = 'download_completed',
  ToggleOn = 'toggle_on',
  ToggleOff = 'toggle_off',
  TableRowClicked = 'table_row_clicked',
  SaveTimelineComment = 'save_timeline_comment',
  CreateTimelineComment = 'create_timeline_comment',
  DeleteTimelineEntry = 'delete_timeline_entry',
  DeleteTimelineComment = 'delete_timeline_comment',
  Collapsed = 'collapsed',
  Expanded = 'expanded',
  DropdownChanged = 'dropdown_changed',
  Translate = 'translate',
  Copy = 'copy',
}
