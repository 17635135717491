import { Injectable } from '@angular/core';
import { ReportsDataManager } from 'app-managers/reports-data.manger';
import { RequestResult } from 'app-models/common/requestResult.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReportsDataService {
  constructor(private reportsDataManger: ReportsDataManager) {}

  getReportsData = (report_id: string): Observable<RequestResult> => {
    return this.reportsDataManger.getReportData(report_id);
  }

}
