import { TableColumnHeaderIconConfiguration } from './header-icons.configuration';
import { AppendixConfiguration } from 'app-models/appendix/appendix-confguration.model';
import { AssetAnalysisReportData } from 'app-models/asset-analysis/asset-analysis-report-data.model';
import { TargetAsset } from 'app-models/assets/target-asset.model';
import { GenericObject } from 'app-models/common/generic-object';
import { Text } from 'app-models/common/text.model';
import { EmptyStateConfiguration } from 'app-models/empty-state/empty-state-configuration.model';
import { TableCellConfiguration } from 'app-models/table/table-cell-configuration.model';
import { TableCellType } from 'app-models/table/table-cell-type.enum';
import { TableColumnHeader } from 'app-models/table/table-column-header.enum';
import { TableConfiguration } from 'app-models/table/table-configuration.model';

type keys = keyof Omit<AssetAnalysisReportData, 'appendixes'>;

const DefaultTableConfiguration: TableConfiguration = {
  styleClass: 'asset-analysis-appendix-table striped-odd',
};

const DefaultEmptStateConfiguration: Partial<EmptyStateConfiguration> = {
  styleClass: 'f-s-24 bold p-xy-10'
};

const DefaultCategoryColumn: TableCellConfiguration<TargetAsset>[] = [
  {
    headers: [{
      text: new Text(TableColumnHeader.Category, true),
      icon: { class: TableColumnHeaderIconConfiguration[TableColumnHeader.Category] }
    }],
    field: 'category',
    types: [TableCellType.Text],
    showIf: ((asset) => !!asset.category),
    needsTranslation: true,
    styleClass: 'break-word',
  },
];

const DefaultAssetNameColumn: TableCellConfiguration<TargetAsset>[] = [
  {
    headers: [{
      text: new Text(TableColumnHeader.AssetName, true),
      icon: { class: TableColumnHeaderIconConfiguration[TableColumnHeader.AssetName] }
    }],
    field: 'name',
    types: [TableCellType.Text],
    showIf: ((asset) => !!asset.name),
    styleClass: 'break-all'
  }
];

const DefaultScoreColumn: TableCellConfiguration<TargetAsset>[] = [
  {
    headers: [{
      text: new Text(TableColumnHeader.Score, true),
      icon: { class: TableColumnHeaderIconConfiguration[TableColumnHeader.Score] }
    }],
    field: 'score',
    types: [TableCellType.Knob],
    styleClass: 'knob-text d-inline-flex justify-content-center align-items-center align-middle',
    width: '100px',
    knobOptions: {
      size: 25
    }
  }
];

const DefaultTagsColumn: TableCellConfiguration<TargetAsset>[] = [
  {
    headers: [{
      text: new Text(TableColumnHeader.AssetTags, true),
      icon: { class: TableColumnHeaderIconConfiguration[TableColumnHeader.AssetTags] }
    }],
    field: 'tags',
    types: [TableCellType.Chips],
    chipClass: 'capitalize f-s-10',
  }
];

export const AssetAnalysisAppendixesConfiguration: GenericObject<AppendixConfiguration, keys> = {
  outdated_technologies: {
    table: {
      ...DefaultTableConfiguration,
      header: new Text('asset_analysis_report.appendixes.outdated_technologies.title', true),
      styleClass: 'asset-analysis-appendix-table striped-odd'
    },
    columns: [
      {
        headers: [{
          text: new Text(TableColumnHeader.Identifier, true),
          icon: { class: TableColumnHeaderIconConfiguration[TableColumnHeader.Identifier] }
        }],
        field: 'display_id',
        types: [TableCellType.Text],
        styleClass: 'break-word',
      },
      ...DefaultCategoryColumn,
      ...DefaultAssetNameColumn,
      ...DefaultScoreColumn,
      ...DefaultTagsColumn,
      {
        field: 'technologies',
        types: [TableCellType.MultiCell],
        headers: [
          {
            text: new Text(TableColumnHeader.Technology, true),
            icon: { class: TableColumnHeaderIconConfiguration[TableColumnHeader.Technology] }
          },
          {
            text: new Text(TableColumnHeader.Versions, true),
            icon: { class: TableColumnHeaderIconConfiguration[TableColumnHeader.Versions] }
          }
        ],
        multiCell: {
          cells: [
            {
              field: 'name',
              types: [TableCellType.Text],
              styleClass: 'break-all',
            },
            {
              field: 'versions',
              types: [TableCellType.Text],
              styleClass: 'break-all',
            },
          ]
        }
      },
      {
        headers: [{
          text: new Text(TableColumnHeader.LastSeenDate, true),
          icon: { class: TableColumnHeaderIconConfiguration[TableColumnHeader.LastSeenDate] }
        }],
        field: 'last_seen_date',
        types: [TableCellType.Date],
      }
    ],
    emptyState: {
      ...DefaultEmptStateConfiguration,
      text: new Text('asset_analysis_report.appendixes.outdated_technologies.empty_state', true),
    }
  }
};
