import { Text } from 'app-models/common/text.model';
import { ListItemConfiguration } from 'app-models/list/list-item-configuration.model';
import { ListItemType } from 'app-models/list/list-item-type.enum';

export const AssetDetailsListConfiguration: ListItemConfiguration[] = [
  {
    title: { text: new Text('identifier', true) },
    path: 'display_id',
    type: ListItemType.Text,
    icon: { class: 'far fa-id-badge' },
    avoidContentTitleCase: true,
    order: 1,
  },
  {
    title: { text: new Text('last_vulnerable_date', true) },
    path: 'last_vulnerable_date',
    type: ListItemType.Date,
    icon: { class: 'far fa-calendar-day' },
    order: 2,
  },
  {
    title: { text: new Text('score', true) },
    path: 'score',
    type: ListItemType.Text,
    icon: { class: 'far fa-exclamation-circle' },
    order: 3,
    needsTranslation: false,
  },
  {
    title: { text: new Text('monitoring', true) },
    path: 'critical',
    type: ListItemType.Text,
    needsTranslation: true,
    icon: { class: 'far fa-radar' },
    order: 4,
  },
  {
    title: { text: new Text('vector_tags', true) },
    path: 'vector_tags',
    type: ListItemType.Chips,
    icon: { class: 'far fa-tags' },
    order: 5,
  }
];
