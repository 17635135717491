import { Component } from '@angular/core';
import { ReportType } from 'app-models/report/report-type.enum';
import { Report } from 'app-models/report/report.model';
import { TargetSummaryReportService } from './target-summary.service';
import { TargetSummaryReportData } from 'app-models/target-summary/target-summary-report-data.model';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-target-summary',
  templateUrl: './target-summary.component.html',
  styleUrls: ['./target-summary.component.scss']
})
export class TargetSummaryReportComponent {
  readonly ReportType = ReportType;
  reportData: TargetSummaryReportData;
  configurations: any;

  constructor(
    private targetSummaryReportService: TargetSummaryReportService,
  ) { }

  onReportData = (report: Report<TargetSummaryReportData>) => {
    this.reportData = report.data;
    this.configurations = this.targetSummaryReportService.getConfigurations(this.reportData);
  }

}
