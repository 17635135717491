import { Injectable } from '@angular/core';
import { DynamicRoutes } from 'app-configurations/dynamicRoutes.configuration';
import { merge } from 'lodash';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApplicationDynamicRoutes {

  constructor(
    private router: Router,
  ) { }

  set = (): void => {
    const routes = this.getRouteConfigurations();
    for (const route of routes) {
      this.router.config.push(route);
    }
  }

  getDefaultRoute = (): string => {
    const routes = this.getRouteConfigurations();
    return routes[0].redirectTo;
  }

  navigate = (route: string[], queryParams?: any, navigationExtars?: NavigationExtras) => {
    const extras: NavigationExtras = merge({ queryParams }, navigationExtars);
    this.router.navigate(route, extras);
  }

  private getRouteConfigurations = () => {
    return DynamicRoutes;
  }
}
