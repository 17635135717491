import { Injectable } from '@angular/core';
import { isEmpty, sortBy } from 'lodash';
import { TimelineStep } from 'app-models/timeline/timeline-step.model';
import { TimelineStepType } from 'app-models/timeline/timeline-step-type.enum';
import { TimelineIcons } from 'app-configurations/timeline-icons.configuration';
import { TimelineEntry } from '../../models/timeline/timeline-entry.model';


@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  buildCommentsTimeline = (timeline: TimelineEntry[]): TimelineStep[] => {
    const steps: TimelineStep[] = [];
    timeline = sortBy(timeline, 'date');
    timeline?.forEach((entry) => {
      entry.comments?.forEach((comment) => {
        comment.avatar = this.getModifiedUserName(comment?.author?.name) || comment?.author?.name;
      });
      steps.push({
        type: TimelineStepType.Comment,
        containerStyleClass: 'light-border-container ' + (!!entry.comments?.length ? 'm-b-10' : ''),
        icon: { class: TimelineIcons[entry.type] },
        content: entry
      });
    });
    if (isEmpty(steps)) {
      return undefined;
    }
    return steps;
  }

  private getModifiedUserName = (name: string): string => {
    let modifiedName = '';
    if (name) {
      const splittedName = name.split(' ');
      splittedName.forEach((item, index) => {
        if (index <= 1) {
          const letter = item.split('')[0];
          modifiedName += (letter);
        }
      });
    }
    return modifiedName;
  }

}
